import './enterData.css'
import { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input'
import React, { useEffect, useState, Fragment, useContext } from "react";
import useRenderCount from "../../renderCountHook";
import DropDown from "../../components/DropDown/dropdown";
import TextInput from "../../components/TextInput/textInput";
import Loader from "../../components/Loader/loader"
import Button from "../../components/Button/button";
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import StringConstant from '../../stringConstant';
import { validateEmail } from "../../utility";
import { useTranslation } from "react-i18next";

const EnterData = (props) => {
   useRenderCount("ENTER DATA")
   const loginUserData = localStorage.getItem('authenticated') ? JSON.parse(localStorage.getItem('authenticated')) : null
   const accountDetails = localStorage.getItem('accountDetails') ? JSON.parse(localStorage.getItem('accountDetails')) : null;
   const [customerTitles, setCustomerTitles] = useState([]);
   const [customerTitle, setCustomerTitle] = useState("");
   const [customerLastName, setCustomerLastName] = useState("");
   const [emailNphone, setemailNphone] = useState("");
   const [phone, setPhone] = useState("");
   const [approvers, setApprovers] = useState([]);
   const [approver, setApprover] = useState(null);
   const [showLoader, setShowLoader] = useState(false);
   const [deliveryBayChannel, setDeliveryBayChannel] = useState([]);
   const [selectBayChannel, setSelectBayChannel] = useState(null);
   const { setMessage } = useContext(ToastContext);
   const navData = { ...props.location.state };
   const [historyData] = useState(localStorage.getItem('enterData') ? JSON.parse(localStorage.getItem('enterData')) : null);
   const { t } = useTranslation();

   //----------Api Call for Customer title, approvers//
   useEffect(() => {
      if(!navData.hasOwnProperty('isSendDeliveryBroadcast')) {
         props.history.replace('/dashboard');
      }
      setShowLoader(true);
      (loginUserData && loginUserData.auto_approve_posts) && setApprover({ id: loginUserData.id, name: `${loginUserData.first_name} ${loginUserData.first_name}` })
      const fetchTitles = async () => {
         const response = await apiRequest('customer_titles', undefined, undefined, setMessage);
         setShowLoader(false)
         if (response) {
            setCustomerTitles(response)
         }
      };
      const fetchApprovers = async () => {
         const response = await apiRequest('approvers', undefined, undefined, setMessage);
         setShowLoader(false)
         if (response) {
            const approvers = response.map(({ id, first_name, last_name }) => {
               return { id, name: first_name + " " + last_name }
            })
            setApprovers(approvers)
         }
      };
      fetchTitles()
      fetchApprovers()
      const fetchDeliverableBayChannels = async () => {
         const response = await apiRequest('delivery_bay_channels', undefined, undefined, setMessage);
         setShowLoader(false)
         if (response) {
            setDeliveryBayChannel(response);
            response.length > 0 && setSelectBayChannel(response[0])
         }
      };

      navData['isSendDeliveryBroadcast'] && fetchDeliverableBayChannels();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if (historyData) {
         setCustomerTitle(historyData.customerTitle);
         setCustomerLastName(historyData.customerLastName);
         setemailNphone(historyData.emails ? historyData.emails.toString() : '')
         setPhone((historyData.phones && historyData.phones.length > 0) ? historyData.phones[0] : '')
         setSelectBayChannel(historyData.selectBayChannel);
         setApprover(historyData.approver)

         localStorage.removeItem('enterData');
      }
   }, [historyData])

   const next = async () => {
      if (accountDetails && accountDetails.require_share_contact_email && !emailNphone) {
         setMessage(`Email can't be empty.`)
         return;
      } else if (accountDetails && accountDetails.require_share_contact_phone && !phone) {
         setMessage(`Phone no. can't be empty.`)
         return;
      } else if (emailNphone || phone) {
         let valid = true;
         const emails = [];
         const phones = [], formattedPhone = [];
         const emailArray = emailNphone ? emailNphone.replace(/\s/g, '').split(",") : [];
         const phoneArray = phone ? phone.replace(/\s/g, '').split(",") : [];
         if (emailArray.length > 0) {
            for (let eNp of emailArray) {
               if (eNp && validateEmail(eNp)) {
                  emails.push(eNp.toLocaleLowerCase())
               } else {
                  valid = false;
                  setMessage(`${eNp} is not a valid email`)
                  break;
               }
            }
         }
         if (phoneArray.length > 0) {
            for (let phone of phoneArray) {
               if (phone && isValidPhoneNumber(phone)) {
                  phones.push(phone)
                  formattedPhone.push(formatPhoneNumber(phone));
               } else {
                  valid = false;
                  setMessage(`${phone} is not a valid US phone no.`);
                  break;
               }
            }
         }
         if (valid) {
            props.history.push({ pathname: '/confirm&Submit', state: { ...props.location.state, customerTitle, approver, customerLastName, emails, phones, formattedPhone, selectBayChannel } })
         }
      } else {
         props.history.push({ pathname: '/confirm&Submit', state: { ...props.location.state, customerTitle, approver, customerLastName, selectBayChannel } })
      }
   };

   useEffect(() => {
      let dataToSave = {
         customerTitle: customerTitle,
         approver: approver,
         customerLastName: customerLastName,
         emails: emailNphone,
         phones: [phone],
         selectBayChannel: selectBayChannel,

      }
      localStorage.setItem('enterData', JSON.stringify(dataToSave))
   }, [customerTitle, approver, customerLastName, emailNphone, phone, selectBayChannel])

   return (
      <Fragment>
         {showLoader && <Loader />}
         <div className="select-vehicle enter-data-body" id="select-vehicle-body enter-data-body pad-btm">
            <div className="ddList">
               <div className="justify-content-center align-items-center ddList_box">
                  <div className={(customerTitle && approver && customerLastName) && 'pb_next'}>
                     <DropDown ddclassName="box-5" list={customerTitles}
                        value={JSON.stringify(customerTitle)}
                        onChange={e => {
                           setCustomerTitle(JSON.parse(e.target.value));
                        }}
                     />
                     <TextInput name="model" title={t(StringConstant.labelCusLastName)} type="text"
                        value={customerLastName}
                        onChange={(e) => setCustomerLastName(e.target.value)}
                     />
                     <TextInput name="model" title={t(StringConstant.labelEmail)} type="text"
                        value={emailNphone}
                        onChange={(e) => setemailNphone(e.target.value)}
                     />
                     <TextInput name="model" title={t(StringConstant.labelPhone)} type='tel'
                        value={phone}
                        onChange={setPhone}
                        maxLength="14"
                        autoFocus={false}
                     />
                     {(navData['isSendDeliveryBroadcast'] && deliveryBayChannel.length > 0) &&
                        <DropDown ddclassName="box-6" list={deliveryBayChannel}
                           value={JSON.stringify(selectBayChannel)}
                           isCallFrom="enterData"
                           onChange={e => {
                              setSelectBayChannel(JSON.parse(e.target.value))
                           }}
                        />
                     }

                     {(loginUserData && loginUserData.auto_approve_posts) ?
                        <h4 className="text-center auto-approved">{t(StringConstant.labelAutoApproved)}</h4> :
                        <DropDown ddclassName="box-6" list={approvers}
                           value={JSON.stringify(approver)}
                           onChange={e => {
                              setApprover(JSON.parse(e.target.value));
                           }}
                        />
                     }
                  </div>
               </div>
            </div>
            <div className='next_fixed_btn'>
               {(customerTitle && approver && customerLastName) && <Button btnClick={next} title={t(StringConstant.labelNext)} />}
            </div>
         </div>
      </Fragment>
   )
}

export default EnterData
