import React from 'react';
import "./home.css"
import Button from "../../components/Button/button";
import ForgotPassword from "../../components/ForgotPassword/forgotPassword"
import useRenderCount from "../../renderCountHook"
import logo from '../../Assets/abn-badge.png';
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";

const isLoggedIn = () => localStorage.getItem("authenticated");

const Home = () => {
   useRenderCount("HOME")
   const { t } = useTranslation();

   return (
      <div className="home-body" id="home-body">
         <div className="row justify-content-center align-items-center home-area">
            <div className="col-10 text-center">
               <img className="logo mb-5" src={logo} alt="logo" />
               <Button to={isLoggedIn() ? "/dashboard" : "/login"} title={t(StringConstant.labelGetStarted)} />
               <Button to="/help" title={t(StringConstant.labelHelp)} />
               <ForgotPassword email="" />

               <p>By using this application you agree to our <a class={"home-eula"} href={"https://app.abn.live/eula"} target={"_blank"}>End User License Agreement.</a></p>
            </div>
         </div>
      </div>
   )
}

export default Home;