import React, { useState, useEffect } from 'react';
import './optionalPhoto.css'
import Button from "../../components/Button/button";
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";

const OptionalPhotoView = (props) => {
    const [cameraImg, setCameraImg] = useState('');
    const navData = { ...props.location.state };
    const { t } = useTranslation();

    useEffect(() => {
        const { cameraImg } = navData
        setCameraImg(cameraImg);
    }, [navData])

    return (
        <div className="oprtionalPhoto-wrapper vertically-middle">
            {cameraImg &&
                <div className="showImg">
                    <img src={cameraImg} alt="choose-img" />
                    <Button navData={navData} to="/enterData" title={t(StringConstant.labelNext)} />
                </div>
            }
        </div>
    )
}

export default OptionalPhotoView