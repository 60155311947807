import React, { useState, useEffect, useContext } from "react";
import './header.css'
import { withRouter } from "react-router";
import backButtonImage from "../../Assets/back-arrow.svg";
import ABNLogo from '../../Assets/abn-live-logo-FINAL_abn-live-logo-wht.png'
import offline from '../../Assets/offline.png'

import ToastContext from "../../Context/toastContext";
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { Offline, Online } from "react-detect-offline";

const Header = (props) => {
   let showBackBtn = false;
   const { setMessage } = useContext(ToastContext);
   const user = JSON.parse(localStorage.getItem("authenticated"));
   const { t } = useTranslation();


   if (props.location.pathname !== '/enterData' && props.location.pathname !== '/optionalPhoto' && props.location.pathname !== '/adjustPhoto'
      && props.location.pathname !== '/optional-photo-view' && props.location.pathname !== '/sendNewSalesBroadcast') {
      localStorage.removeItem('enterData');
      localStorage.removeItem('cameraImg');
      localStorage.removeItem('cameraImgs3Key');
      localStorage.removeItem('postData');
   }




   const helpRedirect = () => {
      props.history.push({ pathname: "/help", state: { from: props.location.pathname } })
   }
   const goBack = () => {
      if (props.location.pathname === '/optionalPhoto' && props.location.state) {
         localStorage.setItem('postData', JSON.stringify(props.location.state))
      } else if (props.location.pathname === '/optional-photo-view') {
         localStorage.setItem('cameraImg', (props.location.state && props.location.state.cameraImg) ? props.location.state.cameraImg : null)
         localStorage.setItem('cameraImgs3Key', (props.location.state && props.location.state.cameraImgs3Key) ? props.location.state.cameraImgs3Key : null)
      } else if (props.location.pathname === '/confirm&Submit') {
         localStorage.setItem('enterData', JSON.stringify(props.location.state))
      } else if (props.location.pathname === '/enterData') {
         localStorage.setItem('adjustPhoto', JSON.stringify(props.location.state))
      }
      props.history.goBack();
   }

   if (props.location.pathname && props.location.pathname.indexOf('/singleUser') > -1) {
      showBackBtn = true
   }

   ReactGA.pageview(window.location.pathname + window.location.search);

   //------Handle complete app header buttons------//
   switch (props.location.pathname) {
      case "/":
         return null
      case "/sendNewSalesBroadcast":
         showBackBtn = true
         break;
      case "/help":
         showBackBtn = true
         break;
      case "/login":
         break;
      case "/dashboard":
         break;
      case "/history":
         break;
      case "/support":
         break;
      case "/users":
         showBackBtn = true
         break;
      case "/about":
         showBackBtn = true
         break;
      case "/post":
         showBackBtn = true
         break;
      case "/optionalPhoto":
         showBackBtn = true
         break;
      case "/optional-photo-view":
         showBackBtn = true
         break;
      case "/enterData":
         showBackBtn = true
         break;
      case "/confirm&Submit":
         showBackBtn = true
         break;
      case "/adjustPhoto":
         showBackBtn = true
         break;
      case "/singleUser":
         showBackBtn = true
         break;
      case "/dealer":
         showBackBtn = true
         break;
      case "/newUserStatus":
         showBackBtn = true
         break;
      case "/myProfile":
         showBackBtn = true
         break;
      case "/reportBug":
         showBackBtn = true
         break;
      case "/videoBroadcast":
         break;
      case "/previousPost":
         showBackBtn = true
         break;
      case "/pttv":
         showBackBtn = true
         break;
      case "/pttvDetail":
         showBackBtn = true
         break;
      case "/selectAccount":
         showBackBtn = true
         break;
      default:
   }

   return (
      <header id='header'>
         <div className="container-fluid text-center w-100 p-0">


            <div className="header-body p-3">
               <img src={ABNLogo} alt="ABN Logo" height="34" />
               {showBackBtn && <img className="backbtn" src={backButtonImage} onClick={goBack} alt="back-button" />}
            </div>
            <Offline> <div class="network" > <img src={offline} alt="Offline" height="50" /> You are offline. Check your network. < br />You can not post events in offline mode.</div></Offline>
         </div>


      </header>
   )
}

const HeaderWithRoutes = withRouter(Header);

export default HeaderWithRoutes;
