import React, { useState, useContext, Fragment, useEffect } from 'react'
import './post.css';
import Button from "../../components/Button/button";
import Modal from "../../components/Modal/modal";
import demoHistoryImg from "../../Assets/demo-img.png";
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import Loader from "../../components/Loader/loader";
import StringConstant from '../../stringConstant'
import { useTranslation } from "react-i18next";
import Img from 'react-image';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Post = ({ location, history }) => {
   const post = location.state ? location.state.data : null;
   const { setMessage } = useContext(ToastContext);
   const [showLoader, setShowLoader] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [loggedinUser, setLoggedinUser] = useState();
   const { t } = useTranslation();

   useEffect(() => {
      let userData = localStorage.getItem('authenticated') ? localStorage.getItem('authenticated') : null
      setLoggedinUser(JSON.parse(userData))
   }, [])

   const confirmReportUser = () => {
      confirmAlert({
         title: 'Please Confirm',
         message: 'Are you sure you want to report this user?  This will deactivate their account.',
         buttons: [
            {
               label: 'Yes',
               onClick: () => duplicate_Post("report")
            },
            {
               label: 'No',
               onClick: () => "#"
            }
         ]
      });
   };

   //--Check user status--//
   let statusClass = " ";
   if (post)
      if (post.approved === true) {
         statusClass = "approved";
      } else if (post.status === "Submitted") {
         statusClass = "submitted";
      } else {
         statusClass = "new";
      }
   const statusClassName = "text-center post-status-" + statusClass;
   //--API call for delete user--//
   const deletePost = async () => {
      setShowLoader(true)
      const response = await apiRequest(`posts/${post.id}`, "DELETE", undefined, setMessage);
      setShowLoader(false)
      if (response) {
         setMessage({ head: "Message", body: t(StringConstant.labelPostDeleteSuccess) })
         window.location.href = "/history"
      }
   }
   //--API call for duplicate --//
   const duplicate_Post = async (callFrom) => {
      setShowLoader(true)
      const response = await apiRequest(`posts/${post.id}/${callFrom}`, "PUT", undefined, setMessage);
      setShowLoader(false)
      if (response) {
         setMessage({ head: "Message", body: t(`Post has been ${callFrom === "report" ? "reported" : callFrom}`) });
         await apiRequest('posts', undefined, undefined, setMessage);
         window.location.href = "/history"
      }
   }
   //--API call for submit for approval post--//
   const submitForApproval = async () => {
      setShowLoader(true)
      const response = await apiRequest(`posts/${post.id}/submit`, "PUT", undefined, setMessage);
      setShowLoader(false)
      if (response) {
         setMessage({ head: "Message", body: t("Post has been Submitted") });
         await apiRequest('posts', undefined, undefined, setMessage);
         window.location.href = "/history"
      }
   };

   //--API call approval post--//
   const approve = async () => {
      let approve_post = true;

      if(post.content_flagged) {
         approve_post = window.confirm(StringConstant.confirmUnsafeContent)
      }

      if( approve_post ) {
         setShowLoader(true);
         const response = await apiRequest(`posts/${post.id}/approve`, "PUT", undefined, setMessage);
         setShowLoader(false);
         if (response) {
            setMessage({ head: "Message", body: t("Post has been Approved.") });
            await apiRequest('posts', undefined, undefined, setMessage);
            window.location.href = "/history"
         }
      }
   };


   return (
      <Fragment>{showLoader && <Loader />}
         <div id="container" className="post-body">
            {post &&
               <div className="post display-block">
                  <div className="banner">
                     <Img src={(post && post.photo) ? post.photo.large : demoHistoryImg} />
                  </div>
                  <h2 className="name">{post.customer_name}</h2>
                  <div className="title">{new Date(post.created_at).toDateString()}</div>
                  <div className="actions">
                     <div className="follow-info">
                        <p>{post.customer_message}</p>
                     </div>
                     <div className={statusClassName}>
                        <p className="m-0 p-1">{post.status}</p>
                     </div>
                     {post.content_flagged && <div>
                        <p className="text-danger text-center">{t(StringConstant.warningContentFlagged)}</p>
                     </div>}
                     <Button btnClick={() => setShowModal(true)} title={t(StringConstant.labelAction)} />
                  </div>
                  <div className="desc">
                     <h6>{post.footer_text}</h6>
                  </div>
                  {showModal &&
                     <Modal show={showModal} handleClose={() => setShowModal(false)}>
                        {/* -----condition for approve post------- */}
                        {(loggedinUser && post.approver_id === loggedinUser.id && loggedinUser.can_approve_post && !post.approved && !post.rejected && post.submitted) &&
                        <Button btnClick={approve} title={t(StringConstant.labelApprovePost)} />
                        }

                        {/* -----condition for submit post------- */}
                        {(loggedinUser && ((loggedinUser.active && (loggedinUser.can_create_post || loggedinUser.site_user)) &&
                           (post.user_id === loggedinUser.id && !post.approved && !post.submitted))) &&
                           <Button btnClick={submitForApproval} title={t(StringConstant.labelSubmitForApproval)} />
                        }

                        {/* -----condition for delete post------- */}
                        {(loggedinUser &&
                           ((post.user_id === loggedinUser.id && !post.approved && !post.submitted) ||
                              loggedinUser.site_user || loggedinUser.admin)) &&
                           <Button btnClick={deletePost} title={t(StringConstant.labelDelete)} />
                        }
                        {/* -----condition for report post------- */}
                        {(loggedinUser && post.user_id !== loggedinUser.id && loggedinUser.active && (loggedinUser.can_approve_post || loggedinUser.admin)) &&
                           <Button btnClick={() => confirmReportUser() } title={t(StringConstant.labelReportThisUser)} />
                        }
                     </Modal>
                  }
               </div>
            }
         </div>
      </Fragment>
   )
}

export default Post
