import React, { useState, useContext, useEffect } from "react";
import "./confirmSubmit.css";
import useRenderCount from "../../renderCountHook"
import Button from "../../components/Button/button";
import Loader from "../../components/Loader/loader";
import ToastContext from "../../Context/toastContext";
import apiRequest from "../../api";
import demoHistoryImg from "../../Assets/demo-img.png";
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";
import Img from 'react-image'
import ReactGA from 'react-ga';

const ConfirmSubmit = (props) => {
   useRenderCount("Confirm & Submit")
   const loggedInUser = localStorage.getItem('authenticated') ? JSON.parse(localStorage.getItem('authenticated')) : null
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const user = localStorage.getItem("authenticated") ? JSON.parse(localStorage.getItem("authenticated")) : null;
   const carData = props.location.state;
   const [postData, setPostData] = useState();
   const { t } = useTranslation();
   const accountDetails = localStorage.getItem("accountDetails") ? JSON.parse(localStorage.getItem("accountDetails")) : null;

   useEffect(() => {
      if(!carData) {
        props.history.replace('/dashboard');
      }
      else {
         if (carData.isComeFrom && carData.isComeFrom === 'previousPost') {
            props.location.state && setPostData(props.location.state.data);
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }
   }, []);

   const handlePostApi = async (apiUrl, apiMethod, body, setMessage) => {
      const response = await apiRequest(apiUrl, apiMethod, body, setMessage);
      setShowLoader(false)
      if (response) {
         setMessage({ head: "Message", body: `${carData.isEdit ? t('Post has been updated') : t('Post has been created')}` });
         props.history.replace("/dashboard")
      } else {

      }
   }

   ///--- Form submission --- //
   const submit = async (submitted) => {
      setShowLoader(true);

      let phoneNoToSend;
      if (carData.phones && carData.phones.length > 0) {
         phoneNoToSend = Number(carData.phones[0].slice(2))
      }

      const body = {
         "customer_name": carData.customerLastName,
         "approver_id": carData.approver ? carData.approver.id : null,
         "customer_title_id": carData.customerTitle ? carData.customerTitle.id : null,
         "share_contact_emails": carData.emails,
         "share_contact_phone": phoneNoToSend ? phoneNoToSend : "",
         "used_year": null,
         "used_make_id": null,
         "used_model": null,
         "used_style": null,
         "submitted": submitted,
         "photo_is_portrait": carData.photoIsPortrait,
         "pwa_version": StringConstant.labelAppVersion
      };
      carData.cameraImg ? body["photo_s3_key"] = carData.cameraImgs3Key : body["photo"] = (carData.carColor && carData.carColor.photo) ? carData.carColor.photo.large : null
      if (carData.isSendDeliveryBroadcast && carData.selectBayChannel) {
         body["channel_id"] = carData.selectBayChannel.id
      }
      if (carData.carYear) {
         body["used_year"] = carData.carYear.id;
         body["used_make_id"] = carData.carMake.id;
         body["used_model"] = carData.carModel;
         body["used_style"] = carData.carStyle;
      }
      if (carData.carColor) {
         body["car_count"] = carData.carCount;
         body["car_color_id"] = carData.carColor.id;
      }

      const apiUrl = carData.isEdit ? `posts/${carData.isEdit}` : `posts`;
      const apiMethod = carData.isEdit ? 'PUT' : 'POST';
         handlePostApi(apiUrl, apiMethod, body, setMessage);

      ReactGA.event({
         category: 'User',
         action: 'Post New Event',
         label: user.first_name +' '+ user.last_name+' - '+accountDetails.name+' - '+accountDetails.dealer_number
      });

      if (carData.emails && carData.emails.length > 0) {
         ReactGA.event({
            category: 'User',
            action: 'Send Email',
            label: user.first_name + ' ' + user.last_name + ' - ' + accountDetails.name + ' - ' + accountDetails.dealer_number
         });
      }

      if (carData.phones && carData.phones.length > 0) {
         ReactGA.event({
            category: 'User',
            action: 'Send SMS',
            label: user.first_name + ' ' + user.last_name + ' - ' + accountDetails.name + ' - ' + accountDetails.dealer_number
         });
      }

   };

   const replayBroadcast = async () => {
      setShowLoader(true);
      const response = await apiRequest(`posts/${postData.id}/replay`, `PUT`, undefined, setMessage);
      ReactGA.event({
         category: 'User',
         action: 'Replay Event',
         label: postData.id +' - ' +user.first_name +' '+ user.last_name+' - '+accountDetails.name+' - '+accountDetails.dealer_number
      });
      setShowLoader(false)
      if (response) {
         setMessage({ head: "Message", body: "Post has been replayed." });
         props.history.goBack()
      }
   }

   return (
       <div id="container" className="container confirmSubmit-body">
          {carData && 
          <div>
             {!carData.isComeFrom &&
             <div className="confirmSubmit">
                <div className="title p-2">
                   <p className="actions text-center p-0">
                      {`Congratulations ${(carData.customerTitle && carData.customerTitle.name !== "None") ? carData.customerTitle.name : ''} ${carData.customerLastName} on the purchase of your ${carData.carMake.name}`} {carData.carModel ? carData.carModel.name : ""} {carData.carStyle ? carData.carStyle.name : ""}
                   </p>
                </div>
                <div className="banner">
                   <div className="showImg">
                      <Img
                          src={carData.cameraImg ? carData.cameraImg : (carData.carColor && carData.carColor.photo) ? carData.carColor.photo.large : demoHistoryImg}/>
                   </div>
                </div>
                <div className="consent">Please have customer click the "Send Now" or "Save for Later" button to confirm
                   consent to use this photo.
                </div>
                <Button btnClick={() => submit(true)} title={(user && user.approver && user.auto_approve_posts) ?
                    t(StringConstant.labelSendNow) : t(StringConstant.labelSubmitForApproval)}/>
                <br/>
                <Button btnClick={() => submit(false)} title={t(StringConstant.labelSaveForLater)}/>
                <br/>
             </div>
             }

             {carData.isComeFrom === 'previousPost' &&
             <div className="confirmSubmit">
                <div className="title p-2">
                   <p className="actions text-center p-0">
                      {postData ? postData.customer_message : ''}
                   </p>
                </div>
                <div className="banner">
                   <div className="showImg">
                      <Img src={(postData && postData.photo) ? postData.photo.large : demoHistoryImg}/>
                   </div>
                </div>
                {(loggedInUser && postData && loggedInUser.access_level !== "User" && loggedInUser.active && postData.approved && (loggedInUser.can_approve_post || loggedInUser.admin)) &&
                <Button btnClick={() => replayBroadcast()} title={t(StringConstant.labelReplayThisBroadcast)}/>
                }
                <br/>
             </div>
             }

             {showLoader && <Loader/>}
          </div>
          }
       </div>
   )
}

export default ConfirmSubmit;
