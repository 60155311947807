import React from "react";
import "./textInput.css";
import Eye from "../../Assets/eye.svg";
import Input from 'react-phone-number-input/input'
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";

const TextInput = React.forwardRef((props, ref) => {
   const { t } = useTranslation();

   return (
      <div className="col-12 mb-4 px-4 inp-pre-owned-col">
         <label className="inp inp-pre-owned">
            {props.type === 'tel' ?
               <Input
                  {...props}
                  country="US"
                  defaultCountry="US"
                  format="US"
                  placeholder="&nbsp;"
               />
               :
               <input
                  {...props}
                  ref={ref}
                  placeholder="&nbsp;"
               />
            }
            <span className="label">{props.title}</span>
            {props.name === "user-password" && <img src={Eye} alt="eye" className="eyepass" onClick={props.handletype ? props.handletype : null} />}
            <span className="border"></span>
         </label>
      </div>
   )
})

export default TextInput