import React, { useEffect, useState, useContext, Fragment } from 'react'
import './pptv.css'
import useRenderCount from "../../renderCountHook"
import Loader from "../../components/Loader/loader"
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import Button from "../../components/Button/button";
import StringConstant from '../../stringConstant';
import DropDown from "../../components/DropDown/dropdown";
import { useTranslation } from "react-i18next";
import addressInfoImage from "../../Assets/abn-badge.png";
import pttvBG from "../../Assets/pttv_big.png"

const PPTVDetail = (props) => {
    useRenderCount("PPTVDetail")
    const [listHours] = useState([
        { id: 1, name: "1 Hour" },
        { id: 2, name: "2 Hours" },
        { id: 3, name: "3 Hours" },
        { id: 4, name: "4 Hours" },
        { id: 5, name: "5 Hours" },
        { id: 6, name: "6 Hours" },
        { id: 7, name: "7 Hours" },
        { id: 8, name: "8 Hours" }
    ]);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedHour, setSelectedHour] = useState(null);
    const { setMessage } = useContext(ToastContext);
    const { t } = useTranslation();
    const [disabled, setDisabled ] = useState(true);
    const [buttonClass, setButtonClass ] = useState('playPPTV_off')

    //-- Set selected hours --//
    useEffect(() => {
        setSelectedHour(listHours[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePlayPPTV = async () => {

        setShowLoader(true)
        const response = await apiRequest(`playable_channels/${props.location.state.data.id}/play`, "PUT", { hours: selectedHour.id }, setMessage);
        setShowLoader(false)
        if (response) {
            setMessage({ head: "Message", body: t(StringConstant.pptvPlayMessage) });
        }
    }

    const handleStopPPTV = async () => {
        setShowLoader(true)
        const response = await apiRequest(`playable_channels/${props.location.state.data.id}/stop`, "PUT", undefined, setMessage);
        setShowLoader(false)
        if (response) {
            setMessage({ head: "Message", body: t(StringConstant.pptvStopMessage) });
        }
    }

    return (
        <Fragment>
            {showLoader && <Loader />}
            <div id="pptvDetailContainer" className="container listview-body-wrapper history-body pad-btm">
                <div className="banner">
                    <img class="pttv_bg" src={pttvBG} alt="abn-logo" />
                </div>
                <div className="row">
                    <DropDown ddclassName="box-1"
                        list={listHours}
                        placeholder="Choose Duration"
                        isCallFrom="pptvDetail"
                        onChange={e => {
                            setSelectedHour(JSON.parse(e.target.value));

                            setDisabled(false)
                            setButtonClass('playPPTV' )

                        }}
                    />
                </div>
                <Button btnClick={handlePlayPPTV} btnClass={buttonClass} title={t(StringConstant.labelPlayPPTV)} style={{ width: '70% !important' }} disabled={disabled} />
               <br/>
                <div className="optPhoto-btn skip-btn">
                <Button btnClick={handleStopPPTV} title={t(StringConstant.labelStopPlayPPTV)} style={{ width: '70% !important' }} />
                </div>
            </div>
        </Fragment>
    )
}

export default PPTVDetail