import React, { useState, useEffect } from "react";
import useRenderCount from "../../renderCountHook";
import Modal from "../../components/Modal/modal";
import "./dropdown.css";
import demoHistoryImg from "../../Assets/demo-img.png";
import StringConstant from '../../stringConstant'
import { useTranslation } from "react-i18next";

const DropDown = ({ list = [], onChange, placeholder, ddclassName, disabled, carColor, setCarColor, image, isCallFrom = null, openModal, handleOpenModal, value }) => {
   useRenderCount("DROP_DOWN");
   const [isShowCarColor] = useState(localStorage.getItem('isShowCarImage'))
   const { t } = useTranslation();
   const settings = {
      dots: false,
      infinite: false,
      // speed: 500,
      lazyLoad: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      vertical: true,
      verticalSwiping: true
   };

   //--car list option title--//
   let title = "Make"
   switch (ddclassName) {
      case "box-0":
         title = "Year"
         break;
      case "box-2":
         title = "Model"
         break;
      case "box-3":
         title = "Style"
         break;
      case "box-4":
         title = "Color"
         break;
      case "box-5":
         title = "Title"
         break;
      case "box-6":
         title = "Approver"
         break;
      case "box-7":
         title = "Type"
         break;
      case "box-8":
         title = "Video"
         break;
      default:
         title = "Make"
   }
   const ddClass = "box col-12 " + ddclassName;

   const [showImgModal, setshowImgModal] = useState(false);
   useEffect(() => {
      setshowImgModal(openModal)
   }, [openModal])

   //--Handle car color--//
   const handleCarColor = (color) => {
      setshowImgModal(false);
      setCarColor(color);
      handleOpenModal()
   }
   //--Render selection option--//
   const renderSelectOption = () => {
      if (!image) {
         return (
             <select onChange={onChange} value={value} disabled={disabled}>
                {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
                {!isCallFrom && <option value={0}>Choose {title}</option>}
                {(list && list.length > 0) && list.map(e => {
                   return (
                       <option value={JSON.stringify(e)} key={e.id}>
                          {e.name}
                       </option>
                   )
                })}
             </select>
         )
      } else {
         return (
             <div className="carImgColor-wrapper">
                {carColor ?
                    null :
                    <div className="carImgColor"
                         onClick={disabled ? undefined : (() => setshowImgModal(true))}
                         style={{ opacity: !disabled ? '1.0' : '0.2', boxShadow: !disabled ? 'none' : '0px 0px 7px 1px rgb(196,37,50)' }} >
                       <p className="m-0">{carColor ? t(StringConstant.labelChangeColor) : t(StringConstant.labelChooseColor)}</p>
                    </div>
                }
             </div>
         )
      }
   }

   return (
       <div className={ddClass}>
          {renderSelectOption()}
          {!disabled && showImgModal &&
          <Modal show={showImgModal} from="selectVehicle" handleClose={() => { setshowImgModal(false); handleOpenModal() }} >
             <div className='slider-wrapper'>
                <div className='slider-scroll'>
                   {(list && list.length > 0) && list.map(e => (
                       <div key={e.id} className="slider" onChange={onChange}>
                          {(isShowCarColor === 'true') ?
                              <img src={e.photo ? e.photo.large : demoHistoryImg} className="slider-img" onClick={() => handleCarColor(e)} />
                              :
                              <div className="slider-img carColor" onClick={() => handleCarColor(e)}>
                                 <span style={{ backgroundColor: `#${e.color}` }}></span>
                              </div>
                          }
                       </div>
                   ))}
                </div>
             </div>
          </Modal>
          }
       </div>
   )
};

export default DropDown