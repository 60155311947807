import React, { useState, useRef, useEffect } from "react";
import useRenderCount from "../../renderCountHook";
import DropDown from "../../components/DropDown/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/button";
import StringConstant from '../../stringConstant';
import { filterArray } from "../../utility";
import { useTranslation } from "react-i18next";
import Img from 'react-image'

const NewCar = ({ carMakeList, isEdit, addClass, isSendDeliveryBroadcast = null, historyData = null, activeTab }) => {
   useRenderCount("SELECT VEHICLE--NEW CAR")
   const loginUserData = localStorage.getItem('accountDetails') ? JSON.parse(localStorage.getItem('accountDetails')) : null
   const [carMake, setCarMake] = useState();
   const [carModel, setCarModel] = useState();
   const [carStyle, setCarStyle] = useState();
   const [carColor, setCarColor] = useState();
   const [carCount, setCarCount] = useState(1);
   const [showModal, setShowModal] = useState(false);
   const [isShowCar] = useState(localStorage.getItem('isShowCarImage') ? localStorage.getItem('isShowCarImage') : 'true');
   let latestList = useRef([]);
   const { t } = useTranslation();

   const isDisabled = (val) => ((val === 0) || (!val));
   const getArray = (list, key, setType) => {
      if (key) {
         const arr = (list && list.length > 0) ? filterArray(list, key.id, setType) : [];
         latestList.current = arr;
         return arr
      }
      return []
   };

   useEffect(() => {
      if (historyData && !historyData.carYear) {
         setCarMake(historyData.carMake ? historyData.carMake : '')
         setCarModel(historyData.carModel ? historyData.carModel : '')
         setCarStyle(historyData.carStyle ? historyData.carStyle : '')
         setCarColor(historyData.carColor ? historyData.carColor : '')
         setCarCount(historyData.carCount ? historyData.carCount : 1)

         localStorage.removeItem('postData');
      }
   }, [historyData])


   const parseJSON = function (json) {
      try {
         return JSON.parse(json)
      }
      catch (e) {
         throw new Error("Unable to parse JSON: " + json + ": latest list " + JSON.stringify(latestList.current));
      }
   };

   //--------------Handle count suffix ------------------//
   const handleSuffix = () => {
      let suffix = "th";
      if (carCount === 1) {
         suffix = "st";
      } else if (carCount === 2) {
         suffix = "nd";
      } else if (carCount === 3) {
         suffix = "rd";
      }
      return suffix
   }

   const handleCarCount = () => {
      if (carCount > 1) {
         setCarCount(carCount - 1)
      }
   }

   useEffect(() => {
      addClass(carColor)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [carColor]);

   useEffect(() => {
      let dataToSave = {
         carMake: carMake,
         carModel: carModel,
         carStyle: carStyle,
         carColor: carColor,
         carCount: carCount
      }
      localStorage.setItem('postData', JSON.stringify(dataToSave))
   }, [carMake, carModel, carStyle, carColor, carCount])

   return (
      <div className="selVeh">
         <div className="ddList">
            <div className="justify-content-center align-items-center">
               <div className="single-user bg-transparent">
                  <DropDown ddclassName="box-1" list={carMakeList}
                     value={JSON.stringify(carMake)}
                     onChange={e => {
                        setCarMake(parseJSON(e.target.value));
                        setCarModel(null)
                        setCarStyle(null)
                        setCarColor(null)
                     }}
                  />
                  <DropDown ddclassName="box-2" list={getArray(carMakeList, carMake, "car_models")}
                     value={JSON.stringify(carModel)}
                     onChange={e => {
                        setCarModel(parseJSON(e.target.value))
                        setCarStyle(null)
                        setCarColor(null)
                     }}
                     disabled={isDisabled(carMake)}
                  />
                  <DropDown ddclassName="box-3" list={getArray(latestList.current, carModel, "car_styles")}
                     value={JSON.stringify(carStyle)}
                     onChange={e => {
                        setCarStyle(parseJSON(e.target.value))
                        setCarColor(null)
                     }}
                     disabled={isDisabled(carModel)}
                  />
                  <DropDown ddclassName="box-4" list={getArray(latestList.current, carStyle, "car_colors")}
                     value={JSON.stringify(carColor)}
                     disabled={isDisabled(carStyle)}
                     carColor={carColor}
                     setCarColor={setCarColor}
                     image={true}
                     isCarColor={true}
                     openModal={showModal}
                     handleOpenModal={() => setShowModal(false)}
                  />
               </div>
            </div>

            {
               carColor && carColor.photo &&
               <div className="row car-image text-center mb-4">
                  <div className="col-12">
                     <div className="vehicle-img">
                        {isShowCar === "true" ?
                           <Img src={carColor.photo.large} onClick={() => setShowModal(true)} />
                           :
                           <div className="selectedCarColor" style={{ backgroundColor: `#${carColor.color}` }} onClick={() => setShowModal(true)}></div>
                        }
                     </div>
                  </div>
               </div>
            }
            {(loginUserData && !loginUserData.disable_car_count) &&
               <div className="row justify-content-center flex-nowrap text-center align-items-center mb-3 carCount">
                  <button type="button" className="btn-circle btn-outline" onClick={handleCarCount}>
                     <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <p className="m-0 p-1">{`This is their ${carCount}${handleSuffix()} ${carMake ? carMake.name.substring(4) : "____"} !`}</p>
                  <button type="button" className="btn-circle btn-outline" onClick={() => setCarCount(carCount + 1)}>
                     <FontAwesomeIcon icon={faPlus} />
                  </button>
               </div>
            }
         </div>
         <div className='next_fixed_btn'>
            {(carColor) && <Button to={"/optionalPhoto"} navData={{ activeTab, carMake, carModel, carStyle, carColor, carCount, isEdit, isSendDeliveryBroadcast }} title={t(StringConstant.labelNext)} btnClass="fixButton" />}
         </div>
      </div>
   )
}

export default NewCar
