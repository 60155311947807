import React, {useState, useEffect, useContext, Fragment} from 'react';
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import localForage from "localforage";

const semver = require('semver');

const SelectAccount = (props)  => {
    const [selectedAccountID, setSelectedAccountID] = useState('');
    const [accountFilter, setAccountFilter] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const { setMessage } = useContext(ToastContext);
    const accountDetails = localStorage.getItem("accountDetails") ? JSON.parse(localStorage.getItem("accountDetails")) : null;
    const currentSelectedAccountID = localStorage.getItem("selectedAccountID");

    useEffect(() => {

        const loadAccountList = async () => {
            const response = await apiRequest('accounts', undefined, undefined, setMessage);
            setShowLoader(false)
            if (response) {
                setAccounts(response)
                setFilteredAccounts(response)
            }
        };
        loadAccountList();
    }, []);


    const selectAccount = async (accountID) => {
        await localStorage.setItem("selectedAccountID", accountID);
        const accountDetails = await apiRequest('accounts/current', 'GET', undefined, setMessage);
        localStorage.setItem("accountDetails", JSON.stringify(accountDetails));
        localForage.removeItem('listCarMake').then(function () {
            props.history.replace('/dashboard');
        });
    }

    const filterAccounts = (filterValue) => {
        setAccountFilter(filterValue)
        let filtered = accounts.filter(account =>
            account.description.toLowerCase().includes(filterValue.toLowerCase())
        );
        setFilteredAccounts(filtered)
    }

    return (
    <Fragment>
        <div className="login-body" id="login-body">
            <div className="row justify-content-center align-items-center login-inner">
                <div className="col-md-12 px-6">
                    {accounts.length > 10 || accounts.length === 0 ? (
                        <div className="account-list">
                            {currentSelectedAccountID && accountDetails && <label>Current Account: {accountDetails.name}</label>}
                            <br />
                            <div className="input-box">
                                <label>Search:</label>
                                <input placeholder={'Begin Typing to Filter Accounts'}
                                       onKeyUp={(e) => {
                                           filterAccounts(e.target.value);
                                       }}/>
                            </div>
                            <ul>
                                {filteredAccounts.filter(account => !currentSelectedAccountID || currentSelectedAccountID !== account.id.toString()).map(account => {
                                    return (
                                        <li key={account.id}
                                            onClick={(e) => {
                                                setSelectedAccountID(account.id);
                                                selectAccount(account.id);
                                            }}><a key={account.id}>{account.description}</a></li>
                                    )
                                })}

                            </ul>
                        </div>) :
                        ( accounts.length > 0 && <div className="user-filter">
                            {currentSelectedAccountID && <label>Current Account: { accountDetails.name}</label>}
                            <select className='select-account' value={selectedAccountID}
                                    onChange={(e) => {
                                        setSelectedAccountID(e.target.value)
                                    }}
                            >
                                <option>Select Your Account</option>
                                {accounts.filter(account => !currentSelectedAccountID || currentSelectedAccountID !== account.id.toString()).map(account => {
                                    return (
                                        <option value={account.id} key={account.id}>{account.description}</option>
                                    )
                                })}
                            </select>
                            <button type="button" className={'buttonCls w-100'}
                                                  onClick={(e) => {
                                                      selectAccount(selectedAccountID)
                                                  }} disabled={!selectedAccountID} >
                                {'Submit'}
                            </button>
                        </div>)
                    }
                </div>
            </div>
        </div>
    </Fragment>
    )
}

export default SelectAccount;
