

import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Header from '../components/Header/header';
import Footer from '../components/Footer/footer';
import Home from "../pages/Home/home";
import SelectVehicle from "./SendNewSalesBroadcast/selectVehicle";
import Help from "../pages/Help/help";
import Authentication from "./Authentication/authentication";
import History from "./History/history";
import Support from "./Support/support";
import Post from "./Post/post";
import Users from "../pages/Users/users"
import Dashboard from "../pages/Dashboard/dashboard"
import MyProfile from "../pages/MyProfile/myProfile"
import ReportBug from "../pages/ReportBug/reportBug"
import ABNInfo from "../pages/ABNInfo/about"
import SingleUser from "../pages/Users/singleUser"
import OptionalPhoto from "../pages/SendNewSalesBroadcast/optionalPhoto"
import OptionalPhotoView from "../pages/SendNewSalesBroadcast/optionalPhotoView"
import EnterData from "../pages/SendNewSalesBroadcast/enterData"
import AdjustPhoto from "../pages/SendNewSalesBroadcast/adjustPhoto"
import ConfirmSubmit from "../pages/SendNewSalesBroadcast/confirmSubmit"
import PageNotFound from "../pages/404/404"
import PreviousPost from "../pages/sendPreviousSalesBroadcast/previousPost"
import NewUserStatus from "../pages/UserStatus/newUserStatus"
import VideoBroadcast from "../pages/sendVideo/videoBroadcast"
import ToastContext from "../Context/toastContext"
import Toast from "../components/Toast/toast"
import PPTV from "../pages/PPTV/pptv"
import PPTVDetail from "../pages/PPTV/pptvDetail"
import PWAPrompt from 'react-ios-pwa-prompt'
import {deviceDetect, isIOS, isMobile, isSafari, isChrome, browserName} from "react-device-detect";
import SelectAccount from "./Authentication/selectAccount";

const isLoggedIn = () => localStorage.getItem("authenticated")  ? localStorage.getItem("authenticated") : null;
const hasAccountDetails = () => localStorage.getItem("authenticated") && localStorage.getItem("accountDetails") ? localStorage.getItem("authenticated") : null;
const App = props => {
   const [message, showMessage] = useState(false);
   useEffect(() => {
      const appCount = localStorage.getItem("iosPwaPrompt") ? JSON.parse(localStorage.getItem("iosPwaPrompt")) : null
      const handleNetworkSpeed = async () => {
         let imageAddr = `https://abn-live-pwa.s3.amazonaws.com/speedtest.jpeg?n=${Math.random()}`;
         let startTime, endTime;
         let downloadSize = 5616998;
         let download = new Image();
         download.onload = await function () {
            endTime = (new Date()).getTime();
            showResults();
         }
         startTime = (new Date()).getTime();
         download.src = imageAddr;

         function showResults() {
            let duration = (endTime - startTime) / 1000;
            let bitsLoaded = downloadSize * 8;
            let speedBps = (bitsLoaded / duration).toFixed(2);
            let speedKbps = (speedBps / 1024).toFixed(2);
            let speedMbps = (speedKbps / 1024).toFixed(2);
            if (speedMbps < 1) {
               alert("You have a poor internet connection and the app may be slow.")
            }
         }
      }
      if (appCount && appCount.visits === 1) {
         handleNetworkSpeed();
      }
   }, [])

   if (message) {
      setTimeout(() => {
         showMessage(false)
      }, 3000)
   }

   return (
      <Router>
         <ToastContext.Provider value={{ message, setMessage: showMessage }}>
            <div className="bg"></div>
            <Header />
            <div className="wrapper">
               <div className="layer">
                  {message && <Toast />}
                  <Suspense fallback={<div>Loading...</div>}>
                     <Switch>
                        <Route path="/" exact render={props => isLoggedIn() ? (<Redirect to="/dashboard" />) : (<Home {...props} />)} />
                        <Route path="/help" render={props => (<Help {...props} />)} />
                        <Route path="/login" render={props => isLoggedIn() ? (<Redirect to="/dashboard" />) : (<Authentication {...props} />)} />
                        <Route path="/newUserStatus" render={props => isLoggedIn() ? (<Redirect to="/dashboard" />) : (<NewUserStatus {...props} />)} />
                        <PrivateRoute path="/sendNewSalesBroadcast" component={SelectVehicle} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <Route path="/selectAccount" render={props => isLoggedIn() ? (<SelectAccount {...props} />) : (<Authentication {...props} />)} />
                        <PrivateRoute path="/history" component={History} />
                        <PrivateRoute path="/support" component={Support} />
                        <PrivateRoute path="/post" component={Post} />
                        <PrivateRoute path="/myProfile" component={MyProfile} />
                        <PrivateRoute path="/reportBug" component={ReportBug} />
                        <PrivateRoute path="/users" component={Users} />
                        <PrivateRoute path="/singleUser" component={SingleUser} />
                        <PrivateRoute path="/about" component={ABNInfo} />
                        <PrivateRoute path="/optionalPhoto" component={OptionalPhoto} />
                        <PrivateRoute path="/optional-photo-view" component={OptionalPhotoView} />
                        <PrivateRoute path="/enterData" component={EnterData} />
                        <PrivateRoute path="/adjustPhoto" component={AdjustPhoto} />
                        <PrivateRoute path="/confirm&Submit" component={ConfirmSubmit} />
                        <PrivateRoute path="/previousPost" component={PreviousPost} />
                        <PrivateRoute path="/videoBroadcast" component={VideoBroadcast} />
                        <PrivateRoute path="/pttv" component={PPTV} />
                        <PrivateRoute path="/pttvDetail" component={PPTVDetail} />
                        <Route path="/" component={PageNotFound} />
                     </Switch>
                  </Suspense>
               </div>
            </div>
            <Footer />

            {isIOS && isChrome ?

                <Redirect to="/help"/>
                :
                isSafari && isIOS ?
                    <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false}/>
                    :
                    <div></div>
            }
         </ToastContext.Provider>
      </Router>
   )
}

function PrivateRoute({ component: Component, ...rest }) {
   return (
      <Route
         {...rest}
         render={props =>
            isLoggedIn()  ?
                hasAccountDetails() ? <Component {...props} /> :
                    (
                        <Redirect
                            to={{
                               pathname: "/selectAccount",
                               state: { from: props.location }
                            }} />
                    )
               :
               (
                  <Redirect
                     to={{
                        pathname: "/login",
                        state: { from: props.location }
                     }} />
               )
         } />
   );
}

export default App;
