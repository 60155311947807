import * as firebase from "firebase/app";
import "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyC_5TRgfwVocSns6QrWaPsoQfFoWdrUCA8",
    authDomain: "abn-live-production.firebaseapp.com",
    databaseURL: "https://abn-live-production.firebaseio.com",
    projectId: "abn-live-production",
    storageBucket: "abn-live-production.appspot.com",
    messagingSenderId: "412027901547",
    appId: "1:412027901547:web:ca0c8a88e5a532786fc32e"
};


firebase.initializeApp(firebaseConfig);
export const FBMessaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

if(FBMessaging) {
    FBMessaging.onMessage(function(payload) {
        alert(payload.notification.body);
    });
}