import React from "react";

const Menu = ({ title, src, className = "", alt, anDelay = 0, styles = null, onClick, isFrom = "", customMargin = "" }) => {
    const classNames = "col-8 custom-b-margin " + className;
    const animationDelay = {
        animationDelay: anDelay
    }
    return (
        <div className={customMargin ? `${customMargin} row` : "row"}>
            <div className={classNames} style={animationDelay}>
                <div className="row align-items-center dash-item buttonCls h-100 ml-0 mr-0" style={styles} onClick={onClick} >
                    <div className="col-5 icon">
                        <img alt={alt} src={src}></img>
                    </div>
                    <div className="col-7 menu-txt">
                        <p className="m-0">{title}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Menu