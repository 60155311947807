import React, { useEffect, useState, useContext, Fragment } from "react";
import "./selectVehicle.css";
import ToastContext from "../../Context/toastContext"
import useRenderCount from "../../renderCountHook";
import Tab from 'react-cool-tabs';
import NewCar from "./newCar";
import PreOwnedCar from "./preOwnedCar";
import Loader from "../../components/Loader/loader";
import apiRequest from "../../api";
import StringConstant from '../../stringConstant';
import * as localForage from "localforage";
import { useTranslation } from "react-i18next";

const SelectVehicle = props => {
   useRenderCount("SELECT VEHICLE")
   const [carMakeList, setCarMakeList] = useState([]);
   const [usedCarYearList, setUsedCarYearList] = useState([]);
   const [usedCarMakeList, setUsedCarMakeList] = useState([]);
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const [selectVehicle, setSelectVehicle] = useState(false);
   const [historyData] = useState(localStorage.getItem('postData') ? JSON.parse(localStorage.getItem('postData')) : null);
   const [activeTab] = useState((localStorage.getItem('postData') && JSON.parse(localStorage.getItem('postData')).carYear) ? "2" : "1");
   const { t } = useTranslation();
   //--API call for car selection--//
   useEffect(() => {
      maybeExpireData().then(function () {
         localForage.getItem('listCarMake').then(function (value) {
            // This code runs once the value has been loaded
            // from the offline store.
            if (value) {
               setCarMakeList(value);
               localForage.getItem('listCarYears').then(function (value) {
                  if (value) {
                     setUsedCarYearList(value);
                     localForage.getItem('listUsedCarMakes').then(function (value) {
                        if (value) {
                           setUsedCarMakeList(value)
                        } else {
                           fetchUsedcarMakes()
                        }
                     }).catch(function () {
                        fetchUsedcarMakes()
                     })
                  } else {
                     fetchUsedcarYears();
                  }
               }).catch(function () {
                  fetchUsedcarYears();
               })

            } else {
               fetchcarMakes();
            }
         }).catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
            fetchcarMakes();
         });
      });

      const fetchcarMakes = async () => {
         setMessage({ head: "Updating", body: `Checking for Vehicle Updates` });
         setShowLoader(true)
         const response = await apiRequest('car_makes', undefined, undefined, setMessage);
         if (response) {
            setCarMakeList(response.reverse());
            localForage.setItem('listCarMake', response).then(function (value) {
               // Do other things once the value has been saved.
            }).catch(function (err) {
               // This code runs if there were any errors
               console.log(err);
            });
            fetchUsedcarYears();
         } else {
            setShowLoader(false)
         }
      };
      const fetchUsedcarYears = async () => {
         const response = await apiRequest('used_car_years', undefined, undefined, setMessage);
         if (response) {
            const used_car_years = response.map(year => {
               return { id: year, name: year }
            })
            setUsedCarYearList(used_car_years)
            localForage.setItem('listCarYears', used_car_years)
            fetchUsedcarMakes()
         } else {
            setShowLoader(false)
         }
      };
      const fetchUsedcarMakes = async () => {
         const response = await apiRequest('used_car_makes', undefined, undefined, setMessage);
         if (response) {
            setShowLoader(false)
            setUsedCarMakeList(response)
            localForage.setItem('listUsedCarMakes', response)
         } else {
            setShowLoader(false)
         }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const AddClass = getClass => {
      getClass ? setSelectVehicle(true) : setSelectVehicle(false);
   }

   const maybeExpireData = async () => {
      return localForage.getItem('carMakesExpirationDate').then(function (value) {
         let now = new Date();
         if(value) {
            if(now > value) {
               localForage.removeItem('listCarMake');
               localForage.removeItem('listCarYears');
               localForage.removeItem('listUsedCarMakes');
               return updateExpirationDate();
            }
         } else {
            return updateExpirationDate();
         }
      });
   };

   const updateExpirationDate = async  () => {
      let tomorrow = new Date(Date.now() + ( 3600 * 1000 * 24));
      return localForage.setItem('carMakesExpirationDate', tomorrow);
   };

   useEffect(() => {
      document.getElementsByClassName('selVeh')[0].parentNode.classList.add('selVeh_wrapper');
   }, []);

   return (
      <Fragment>
         {showLoader && <Loader />}
         <div className={`select-vehicle ${(selectVehicle && activeTab === "1") ? 'selectVehicle' : ''}`} id="select-vehicle-body">
            <Tab
               tabKey={activeTab}
               className={'tab-wrapper'}
               activeTabStyle={{ background: 'transparent', color: 'white' }}
               unActiveTabStyle={{ background: 'transparent', color: '#fff' }}
               activeLeftTabBorderBottomStyle={{ height: 2, background: '#C42532' }}
               activeRightTabBorderBottomStyle={{ height: 2, background: '#C42532' }}
               tabsBorderBottomStyle={{ height: 4 }}
               leftContentStyle={{ color: 'white' }}
               rightContentStyle={{}}
               leftTabTitle={t(StringConstant.labelNewTab)}
               rightTabTitle={t(StringConstant.labelPreOwnedTab)}
               leftContent={
                  <NewCar
                     activeTab
                     addClass={AddClass}
                     carMakeList={carMakeList}
                     isEdit={(props.location.state && props.location.state.id) ? props.location.state.id : null}
                     isSendDeliveryBroadcast={(props.location.state && props.location.state.isSendDeliveryBroadcast) ? props.location.state.isSendDeliveryBroadcast : null}
                     historyData={historyData}
                  />
               }
               rightContent={
                  <PreOwnedCar
                     activeTab
                     usedCarYearList={usedCarYearList}
                     usedCarMakeList={usedCarMakeList}
                     isEdit={(props.location.state && props.location.state.id) ? props.location.state.id : null}
                     isSendDeliveryBroadcast={(props.location.state && props.location.state.isSendDeliveryBroadcast) ? props.location.state.isSendDeliveryBroadcast : null}
                     historyData={historyData}
                  />
               }
               contentTransitionStyle={'transform 0.3s ease-in'}
               borderTransitionStyle={'all 0.3s ease-in'} />
         </div>
      </Fragment>
   )
}

export default SelectVehicle;
