import React from "react";
import './footer.css'
import { withRouter } from "react-router";
import FooterItem from "./footerItem";
import HomeImage from '../../Assets/home-white.svg';
import ActiveHomeImage from '../../Assets/home-red.svg';
import HistoryImage from '../../Assets/history-grey.svg';
import ActiveHistoryImage from '../../Assets/history-red.svg';
import SettingsImage from '../../Assets/settings-white.svg';
import ActiveSettingsImage from '../../Assets/settings-red.svg';
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";

const Footer = ({ location }) => {
   const { t } = useTranslation();

   let hiddenMenuPaths = ['/login', '/help', '/dealer', '/newUserStatus', '/selectAccount']
   if (hiddenMenuPaths.includes(location.pathname)) {
      return null
   }

   let selectHomeIcon = HomeImage, selectHistoryIcon = HistoryImage, selectSupportIcon = SettingsImage;

   switch (location.pathname) {
      case "/sendNewSalesBroadcast":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/dashboard":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/history":
         selectHistoryIcon = ActiveHistoryImage;
         break;
      case "/support":
         selectSupportIcon = ActiveSettingsImage;
         break;
      case "/users":
         selectSupportIcon = ActiveSettingsImage;
         break;
      case "/about":
         selectSupportIcon = ActiveSettingsImage;
         break;
      case "/post":
         selectHistoryIcon = ActiveHistoryImage;
         break;
      case "/optionalPhoto":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/optional-photo-view":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/enterData":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/adjustPhoto":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/confirm&Submit":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/myProfile":
         selectSupportIcon = ActiveSettingsImage;
         break;
      case "/reportBug":
         selectSupportIcon = ActiveSettingsImage;
         break;
      case "/previousPost":
         selectHomeIcon = ActiveHomeImage;
         break;
      case "/pttv":
         selectSupportIcon = ActiveSettingsImage;
         break;
      case "/pttvDetail":
         selectSupportIcon = ActiveSettingsImage;
         break;
      default:
         selectHomeIcon = HomeImage;
         selectHistoryIcon = HistoryImage;
         selectSupportIcon = SettingsImage;
   }

   if (location.pathname && location.pathname.indexOf('/singleUser') > -1) {
      selectSupportIcon = ActiveSettingsImage;
   }
   return (
      <footer>
         <div className="text-center w-100 p-0">
            <div className="footer-body">
               <div className="row align-items-center h-100">
                  <FooterItem title={t(StringConstant.labelMainMenu)} src={selectHomeIcon} to="/dashboard" />
                  <FooterItem title={t(StringConstant.labelHistory)} src={selectHistoryIcon} to="/history" />
                  <FooterItem title={t(StringConstant.labelSupport)} src={selectSupportIcon} to="/support" />
               </div>
            </div>
         </div>
      </footer>
   )
}

const FooterWithRoutes = withRouter(Footer);

export default FooterWithRoutes;
