import React, { useState, useContext } from "react";
import "./reportBug.css";
import "../../components//TextInput/textInput.css";
import TextInput from "../../components/TextInput/textInput";
import StringConstant from '../../stringConstant';
import Button from "../../components/Button/button";
import ToastContext from "../../Context/toastContext";
import Loader from "../../components/Loader/loader";
import apiRequest from "../../api";
import { useTranslation } from "react-i18next";

const ReportBug = (props) => {
    const user = localStorage.getItem("authenticated") ? JSON.parse(localStorage.getItem("authenticated")) : null;
    const [showLoader, setShowLoader] = useState(false);
    const [userName] = useState(user ? `${user.first_name} ${user.last_name}` : "");
    const [email] = useState(user ? user.email : "");
    const [delearship] = useState(user ? user.dealer_number : "");
    const [descError, setDescError] = useState("");
    const { setMessage } = useContext(ToastContext);
    const { t } = useTranslation();
    const accountDetails = localStorage.getItem("accountDetails") ? JSON.parse(localStorage.getItem("accountDetails")) : null;

    const handleReportBug = async () => {
        if (!descError) {
            setMessage({ head: "Message", body: `${t(StringConstant.reportBugDescriptionError)}` });
            return;
        }

        const body = {
            device_type: navigator.userAgent,
            error_description: descError
        }
        setShowLoader(true);
        const response = await apiRequest('reported_defects', 'POST', body, setMessage);
        setShowLoader(false)
        if (response) {
            setMessage({ head: "Message", body: t(StringConstant.reportBugSuccessMsg) })
            props.history.goBack();
        }
    }

    return (
        <div id="container" className="h-100 p-2 m-3">
            {showLoader && <Loader />}
            <TextInput name="model" title={t(StringConstant.reportBugUserName)} type="text"
                value={userName}
                disabled
            />
            <TextInput name="model" title={t(StringConstant.labelEmail)} type="text"
                value={email}
                disabled
            />
            <TextInput name="model" title={t(StringConstant.reportBudDelearship)} type="text"
                value={accountDetails.dealer_number}
                disabled
            />
            <div className="col-12 mb-4 px-4 inp-pre-owned-col">
                <label className="inp inp-pre-owned">
                    <textarea rows="5" value={descError} onChange={(e) => setDescError(e.target.value)}></textarea>
                    <span className="label">{t(StringConstant.reportBugDescError)}</span>
                    <span className="border"></span>
                </label>
            </div>
            <Button btnClick={handleReportBug} title={t(StringConstant.labelReportABug)} />
        </div>
    )
}

export default ReportBug;
