import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";

import translationEng from "./locales/en/translation.json";
import translationSpanish from "./locales/spanish/translation.json";

i18n.use(Backend).use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: translationEng
        },
        spanish: {
            translations: translationSpanish
        }

    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true,
        useSuspense: false
    }
});

export default i18n;