import React, { useEffect, useState, useContext }  from 'react';
import './listView.css'
import { Link } from 'react-router-dom';
import nextArrowImage from "../../Assets/next.svg";
import demoHistoryImg from "../../Assets/demo-img.png";
import acceptedUser from "../../Assets/tick.png";
import inactiveUser from "../../Assets/cross.png";
import newUser from "../../Assets/plus.png";
import Img from 'react-image'
import StringConstant from '../../stringConstant'
import { useTranslation } from "react-i18next";
import LazyLoad from 'react-lazyload';
import {UserData} from "../../userData";
import ToastContext from "../../Context/toastContext";
import Loader from "../../pages/Users/singleUser";

const ListView = ({ data, to, from, isFrom = null }) => {
   const notUser = from !== "USERLIST";
   const [loggedInUser, setLoggedInUser] = useState(null);
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();

   let statusClass = " ";
   if (data.approved === true) {
      statusClass = "approved";
   } else if (data.status === "Submitted") {
      statusClass = "submitted";
   } else {
      statusClass = "new";
   }
   const statusClassName = "mb-0 listview-body-" + statusClass;

   useEffect(() => {
      new UserData(setMessage, setShowLoader, null).maybeFetchData(setLoggedInUser);
   }, []);
   return (
      <div className="col-12 tab-hor">
         <Link to={{ pathname: to, state: { data, isComeFrom: 'previousPost' } }}>
            <div className={(data && loggedInUser && !notUser && !loggedInUser.admin) ? "listview-body listview-minheight" : "listview-body"}>
               <div className="list-data">
                  <div className="row">
                     {
                        (notUser && isFrom !== "pttv") &&

                        <div className="col-3 img">
                           <LazyLoad height={5} overflow>
                          <Img src={(data && data.photo) ? data.photo.small : demoHistoryImg} />
                           </LazyLoad>

                        </div>
                     }
                     {(data && loggedInUser && !notUser && loggedInUser.admin) &&
                        <div className="col-3 img">
                           <LazyLoad height={5} overflow>
                           <Img src={`${data.status === "Accepted" ? acceptedUser : data.status === "Inactive" ? inactiveUser : newUser}`} />
                           </LazyLoad>
                           </div>
                     }
                     {isFrom === "pttv" ?
                        <div className="col-8">
                           <p className="cus-title his-title mb-0" style={{ padding: 18 }}>{data.name}</p>
                        </div>
                        :
                        <div style={{ display: (loggedInUser && !notUser && loggedInUser.admin) ? "initial" : "initial" }} className={(loggedInUser && loggedInUser.admin) ? "col-8" : "col-8"}>


                           <p className="cus-title his-title mb-0">{notUser ? "To: "+data.customer_name_with_title : (data.first_name +' '+ data.last_name)}</p>
                           <p className="cus-titleDate his-title mb-0">{notUser ? "From: "+data.user_name : ""}</p>


                           {isFrom === "history" && <p className="cus-title his-title mb-0">{data.user_name}</p>}

                           {notUser && <p className="listview-time mb-0">{new Date(data.created_at).toLocaleTimeString('en-US', { hour12: true, timeStyle: "short" })}</p>}

                           {(loggedInUser && !notUser && loggedInUser.admin) &&
                              <React.Fragment>
                                 <p className="cus-titleDate his-title mb-0">User Since: {data.created_at ? new Date(data.created_at).toLocaleDateString("en-US") : ''}</p>
                              </React.Fragment>
                           }
                        </div>
                     }
                     <div className="col-1 listview-arrow">
                        <img src={nextArrowImage} alt="next-button" />
                     </div>
                  </div>
               </div>
               <div className="row text-center">
                  {
                     (notUser && isFrom !== "pttv") &&
                     <div className="col-6 offset-3">
                        <p className={statusClassName}>{isFrom === 'previousPost' ? `${data.broadcast_count} ${t(StringConstant.labelPreview)}` : `${data.status}`}</p>
                     </div>

                  }
                  {
                     (!notUser && isFrom !== "pttv" && loggedInUser && loggedInUser.admin) &&
                     <div className="col-6 offset-3">
                        <p className={`mb-0 listview-body-rejected`}>{data.event_count} {t(StringConstant.labelSales)}</p>
                     </div>
                  }
               </div>
            </div>
         </Link>
         {showLoader && <Loader />}
      </div>
   )
}

export default ListView;
