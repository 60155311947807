import React, { useState, useContext, useRef, useEffect } from 'react'
import "./myProfile.css"
import { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input'
import singleUserImage from "../../Assets/single-user.svg"
import TextInput from "../../components/TextInput/textInput"
import Button from "../../components/Button/button";
import apiRequest from "../../api";
import Loader from "../../components/Loader/loader"
import ToastContext from "../../Context/toastContext";
import { withRouter } from "react-router";
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";

const MyProfile = (props) => {
   const parseFormattedPhoneNumber = (number) => {
      if(number) {
         let parsed_number = "+1";
         parsed_number += number.replace(/\s|-|\(|\)/g, '');
         return parsed_number;
      }
      else {
         return number;
      }
   };

   const [showLoader, setShowLoader] = useState(false);
   const [isShowCar, setIsShowCar] = useState(localStorage.getItem('isShowCarImage') ? localStorage.getItem('isShowCarImage') === 'true' ? true : false : true);
   const { setMessage } = useContext(ToastContext);
   const user = JSON.parse(localStorage.getItem("authenticated"));
   const first_name = useRef();
   const last_name = useRef();
   const [mobile_phone, setPhone] = useState(parseFormattedPhoneNumber(user.mobile_phone));
   const retention_days = useRef();
   const [isSpanish, setIsSpanish] = useState(localStorage.getItem('isSpanish') && localStorage.getItem('isSpanish') === "false" ? false : true)
   const { t, i18n } = useTranslation();

   useEffect(() => {
      localStorage.setItem('isShowCarImage', isShowCar ? 'true' : 'false')
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isShowCar]);

   useEffect(() => {
      i18n.changeLanguage(isSpanish ? "spanish" : "en")
   }, [isSpanish, i18n])

   //--API call for update user--//
   const updateUser = async () => {
      if (!retention_days.current.value || Number(retention_days.current.value) < 7) {
         setMessage({ head: "Message", body: `${t(StringConstant.labelPostRetentionDayError)}` });
         return;
      }
      let formatted_mobile_phone = mobile_phone;

      if(mobile_phone) {
         if(isValidPhoneNumber(mobile_phone)) {
            formatted_mobile_phone = formatPhoneNumber(mobile_phone);
         } else {
            setMessage(`${mobile_phone} is not a valid US phone no.`);
            return;
         }
      }

      setShowLoader(true);
      const body = {
         "first_name": first_name.current.value,
         "last_name": last_name.current.value,
         "mobile_phone": formatted_mobile_phone,
         "history_retention_days": retention_days.current.value
      };

      const response = await apiRequest(`users/${user.id}`, "PUT", body, setMessage);
      setShowLoader(false)
      if (response) {
         user["first_name"] = first_name.current.value;
         user["last_name"] = last_name.current.value;
         user["mobile_phone"] = formatted_mobile_phone;
         user["history_retention_days"] = retention_days.current.value;
         localStorage.setItem("authenticated", JSON.stringify(user))
         setShowLoader(false)
         setMessage({ head: "Message", body: t(StringConstant.labelUserUpdateSuccess) });
         props.history.goBack();
      }
   };

   //--API call for reset password--//
   const resetPassword = async () => {
      const body = {
         "email": user.email
      }
      setShowLoader(true);
      const response = await apiRequest('reset_password', 'PUT', body, setMessage);
      setShowLoader(false)
      if (response) {
         setMessage({ head: "Message", body: t(StringConstant.labelSuccessPasswordChange) })
      }
   }

   const refreshUserDetail = async () => {
      setShowLoader(true);
      const response = await apiRequest('accounts/current', 'GET', undefined, setMessage);
      setShowLoader(false)
      if (response) {
         localStorage.setItem("accountDetails", JSON.stringify(response))
      }
   }

   const handleTextTransform = () => {
      setIsSpanish(!isSpanish);
      localStorage.setItem('isSpanish', !isSpanish)
   }

   return (
      <div id="container" className="container myProfile-body">
         <div className="post">
            <div className="profile-logo">
               <img src={singleUserImage} alt="user-img" />
            </div>
            <h2 className="name m-3">{user.email}</h2>
            <div className="actions single-user">
               <div className="follow-info">
                  <TextInput name=""
                     defaultValue={user.first_name}
                     title={t(StringConstant.labelEnterFname)}
                     type="text"
                     ref={first_name}
                  />
                  <TextInput name=""
                     defaultValue={user.last_name}
                     title={t(StringConstant.labelEnterLname)}
                     type="text"
                     ref={last_name}
                  />
                  <TextInput name=""
                     value={mobile_phone}
                     title={t(StringConstant.labelEnterMobile)}
                     type="tel"
                     onChange={setPhone}
                  />
                  <TextInput name=""
                     title={t(StringConstant.labelPostRetentionDay)}
                     defaultValue={user.history_retention_days}
                     type="number"
                     ref={retention_days}
                  />
               </div>
               <div className="follow-btn text-center">
                  <Button title="Save" btnClick={updateUser} />
               </div>
               <div className="auto-approved container" style={{ display: "flex", justifyContent: "space-between" }}>
                  <p className="float-left">{t(StringConstant.labelShowCarColor)}</p>
                  <div className="checkbox switcher">
                     <label htmlFor="carImage">
                        <input type="checkbox" id="carImage" checked={isShowCar} onChange={() => { setIsShowCar(!isShowCar); }} />
                        <span><small></small></span>
                     </label>
                  </div>
               </div>
               <div className="auto-approved container" style={{ display: "flex", justifyContent: "space-between" }}>
                  <p className="float-left">{t(StringConstant.labelToggleSpanish)}</p>
                  <div className="checkbox switcher">
                     <label htmlFor="textTransform">
                        <input type="checkbox" id="textTransform" checked={isSpanish} onChange={handleTextTransform} />
                        <span><small></small></span>
                     </label>
                  </div>
               </div>
               <div className="restPasswordSection">
                  <label onClick={resetPassword}>{t(StringConstant.labelResetPassword)}</label>
                  <span>|</span>
                  <label onClick={refreshUserDetail}>{t(StringConstant.labelRefreshUserDetail)}</label>
               </div>
            </div>
         </div>
         {showLoader && <Loader />}
      </div>
   )
}

export default withRouter(MyProfile)