import React, { useState, Fragment, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './support.css'
import usersImage from '../../Assets/users_2.svg';
import profileImage from '../../Assets/user_3.svg';
import pptvImage from '../../Assets/television.svg';
import reportBugImage from '../../Assets/bug.png';
import infoImage from '../../Assets/worldwide.svg';
import logoutImage from '../../Assets/logout.svg';
import Modal from "../../components/Modal/modal";
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import Loader from "../../components/Loader/loader"
import StringConstant from '../../stringConstant';
import Menu from '../Dashboard/menu';
import { useTranslation } from "react-i18next";
import localForage from "localforage";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const Support = props => {
   const [showModal, setShowModal] = useState(false);
   const [showLoader, setShowLoader] = useState(false);
   const [canSwitchAccounts, setCanSwitchAccounts] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();

   const logOut = () => {
      localStorage.removeItem("authenticated");
      localStorage.clear();
      sessionStorage.clear();
      localForage.removeItem('listCarMake').then(function () {
         // Run this code once the database has been entirely deleted.
         console.log('Database is now empty.');
      }).catch(function (err) {
         // This code runs if there were any errors
         console.log(err);
      });
      props.history.replace("/");
   }

   useEffect(() => {
      setCanSwitchAccounts(localStorage.getItem('canSwitchAccounts') === 'true');
   }, []);

   const confirmLogout = () => {
      confirmAlert({
         title: 'Please Confirm',
         message: 'Are you sure you want to reset and log out?  All data will be purged from your device.',
         buttons: [
            {
               label: 'Yes',
               onClick: () => logOut()
            },
            {
               label: 'No',
               onClick: () => "#"
            }
         ]
      });
   };

   //--API call for check account status--//
   const checkAccountStatus = async () => {
      setShowLoader(true)
      const response = await apiRequest(`accounts/current`, undefined, undefined, setMessage);
      setShowLoader(false)
      if (response.account_up) {
         setMessage({ head: t(StringConstant.labelAccountStatus), body: t(StringConstant.labelSuccessNetwork) })
      } else {
         setMessage({ head: t(StringConstant.labelAccountStatus), body: t(StringConstant.labelErrorNetwork) })
      }
   }

   return (
      <Fragment>
         {
            showModal &&
            <Modal show={showModal} handleClose={() => setShowModal(false)}>
               <p className="reset-pass">{t(StringConstant.labelSuccessPasswordChange)}</p>
            </Modal>
         }
         {showLoader && <Loader />}
         <div className="support-body pad-btm">
            <div className="dashboard-body" id='dashboard-body'>
               <Link to='/users'>
                  <Menu src={usersImage} className='animate-one' anDelay='0s' title={t(StringConstant.labelShowUser)} />
               </Link>
               <Link to='/myProfile'>
                  <Menu src={profileImage} className='animate-two' anDelay='0.2s' title={t(StringConstant.labelMyProfile)} />
               </Link>
               <Link to='/pttv'>
                  <Menu className='animate-one' src={pptvImage} anDelay='0.4s' title={t(StringConstant.labelPPTV)} />
               </Link>
               <Link to='/about'>
                  <Menu className='animate-two' src={infoImage} anDelay='0.6s' title={t(StringConstant.labelAbnInfo)} />
               </Link>
               <Link to='/reportBug'>
                  <Menu className='animate-one' anDelay='0.8s' src={reportBugImage} title={t(StringConstant.labelReportBug)} />
               </Link>
               {canSwitchAccounts && <Link to='/selectAccount'>
                  <Menu src={profileImage} className='animate-two' anDelay='0.9s' title={t(StringConstant.labelSwitchAccount)} />
               </Link>}
               <Link to='#'>
                  <Menu className={canSwitchAccounts ? 'animate-one' : 'animate-two'} onClick={confirmLogout} anDelay='1.0s' src={logoutImage} title={t(StringConstant.labelLogout)} />
               </Link>

            </div>
         </div>
      </Fragment>
   )
}

export default Support
