import React, { useContext, useState } from "react";
import TextInput from "../../components/TextInput/textInput";
import Button from "../../components/Button/button";
import ToastContext from "../../Context/toastContext";
import apiRequest from "../../api";
import {FBMessaging} from "../../firebaseConfig";
import { withRouter } from "react-router";
import ForgotPassword from "../../components/ForgotPassword/forgotPassword";
import StringConstant from '../../stringConstant'
import { useTranslation } from "react-i18next";

const LogIn = ({ email, askEmail, setShowLoader, location, history }) => {
   const [password, setPassword] = useState('');
   const [showPassword, setShowPassword] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const { from } = location.state || { from: { pathname: "/dashboard" } };
   const { t } = useTranslation();

   //--Login form validation--//
   const validateForm = () => {
      let valid = true;
      if (!password.trim()) {
         valid = false;
         setMessage(t("Enter Password"))
      }
      return valid;
   };

   //--API call for Login--//   
   const doLogIn = async () => {
      const validForm = validateForm();
      if (validForm) {
         setShowLoader(true);
         const body = {
            "email": email,
            "password": password
         };
         const response = await apiRequest("sign_in", "POST", body, setMessage);
         if (response) {
            if (response.error) {
               setShowLoader(false);
               history.replace("/newUserStatus", { message: response.responseObject.errors[0], email, isFrom: 'login' })
            } else {
               requestNotificationToken(response.id);
               await localStorage.setItem("authenticated", JSON.stringify(response))
               localStorage.setItem("isShowCarImage", true)
               localStorage.setItem("isDontShowCameraWarning", false)
               localStorage.setItem("isSpanish", false)
               setShowLoader(false)

               const accountList = await apiRequest('accounts', undefined, undefined, setMessage)
               if(accountList.length > 1) {
                  await localStorage.setItem("canSwitchAccounts", 'true');
                  history.replace('/selectAccount');
               } else {
                  const selectedAccountID = accountList[0].id;
                  await localStorage.setItem("selectedAccountID", selectedAccountID);
                  const accountDetails = await apiRequest('accounts/current', 'GET', undefined, setMessage);
                  localStorage.setItem("accountDetails", JSON.stringify(accountDetails));
                  history.replace('/dashboard');
               }
            }
         }
         setShowLoader(false)
      }
   };

   const requestNotificationToken = async (account_id) => {
      if(FBMessaging) {
         FBMessaging.requestPermission()
             .then(function () {
                return FBMessaging.getToken();
             }).then(function (token) {
            storeToken(token, account_id);
         }).catch(function (error) {
            console.log(error);
         });
      }
   };

   const storeToken = async (token, account_id) => {
      const body = {
            "token": token
         };
      const response = await apiRequest("users/" + account_id + "/messaging_tokens", "POST", body, setMessage);
   };

   return (
      <div className="row justify-content-center align-items-center login-inner">
         <div className="col-12">
            <TextInput name="user-password" title={t(StringConstant.labelEnterYourPassword)} type={showPassword ? "text" : "password"}
               value={password}
               onChange={(e) => {
                  setMessage(false)
                  setPassword(e.target.value)
               }}
               handletype={() => setShowPassword(!showPassword)}
            />
            <div className="col-12">
               <Button title={t(StringConstant.labelSignIn)} btnClick={doLogIn} />
               <ForgotPassword email={email} />
            </div>
            <div className="password-info">
               <div className="pass-info-up">
                  <p>{t(StringConstant.labelPasswordFor)}  </p><p></p><span>{email}</span>
                  <Button btnClick={askEmail} title={t("Not this email Id?")} btnClass="for-pass text-center not-email" />
               </div>
            </div>
         </div>
      </div>
   )
}

export default withRouter(LogIn)
