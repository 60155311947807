import React from 'react';
import './modal.css';
import useRenderCount from "../../renderCountHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ show, handleClose, children, from = null }) => {
    useRenderCount("MODAL")
    return (
        <div className={from ? "modals overlay-fixed select-vehicle-modal" : show ? "modals overlay-fixed" : null}>
            <section className="modals-main rgs_form"
            >
                {children}
                <div onClick={handleClose} className="close-icon">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </div>
            </section>
        </div>
    )
};

export default Modal