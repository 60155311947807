import React from "react";
import "./AccountLogo.css";
import baseURL from '../../baseUrl';

const AccountLogo = ({account},{accountName}) => {
    let src = baseURL.replace('api/', '')
    src += 'accounts/' +account+ '/logo_large'

    return (
        <img id="account_logo"
             src={src}
             alt={accountName}
        />
    )

}

export default AccountLogo
