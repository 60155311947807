import React, {useState, useEffect, useContext} from 'react';
import './dashboard.css'
import useRenderCount from "../../renderCountHook"
import icon1 from '../../Assets/send-new-sales-broadcast-icon.png';
import icon2 from '../../Assets/send-previous-sales-broadcast-icon.png';
import icon4 from '../../Assets/send-delivery-broadcast-only.png';
import Menu from './menu';
import { Link } from 'react-router-dom';
import StringConstant from '../../stringConstant';
import Modal from "../../components/Modal/modal";
import Button from "../../components/Button/button";
import AccountLogo from "../../components/AccountLogo/AccountLogo";
import { useTranslation } from "react-i18next";
import {deviceDetect, isIOS, isMobile, isSafari} from "react-device-detect";
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";

const semver = require('semver');

const Dashboard = props => {
   useRenderCount("DASHBOARD");
   const [loggedinUser, setLoggedinUser] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();
   const [isCameraWarning, setIsCameraWarning] = useState((localStorage.getItem('isDontShowCameraWarning') && localStorage.getItem('isDontShowCameraWarning') === 'false') ? false : true)
   const [isAlertCameraWarning, setIsAlertWarning] = useState(false);
   const accountDetails = localStorage.getItem("accountDetails") ? JSON.parse(localStorage.getItem("accountDetails")) : null;

   useEffect(() => {

       const checkversion = async()  => {

           const response = await apiRequest(`company`, undefined, undefined, setMessage);

           if(response && response.current_mobile_version && !isVersionLatest(StringConstant.labelAppVersion, response.current_mobile_version)) {
               let versionUpdateTries = localStorage.getItem("versionUpdateTries") ? parseInt(localStorage.getItem("versionUpdateTries")) : 0;
               if(versionUpdateTries >= 3) {
                   throw new Error("Unable to update app version for user " + accountDetails.email + " user is on " + StringConstant.labelAppVersion)
               } else {
                   localStorage.setItem("versionUpdateTries", (versionUpdateTries + 1));
                   window.location.reload();
               }
           } else {
               localStorage.setItem("versionUpdateTries", '0');
           }
       };

       checkversion();
      const checkforstatechange =  async () => {
         let deviceType =  deviceDetect();
         const { osVersion } = await deviceType
         const semverOsVersion = semver.coerce(osVersion);

         if (isIOS && isMobile && isSafari && deviceType && (semver.lt(semverOsVersion, '13.4.1') ))  {

               if (!isCameraWarning) {
                  setIsAlertWarning(true)
               } else {
                  setIsAlertWarning(false)
               }
            }

      }
      checkforstatechange();
   }, []);

   useEffect(() => {
      let userData = localStorage.getItem('authenticated') ? localStorage.getItem('authenticated') : null
      setLoggedinUser(JSON.parse(userData))
   }, [])

   const opacity = { opacity: 0.3 }

   const isVersionLatest = function(currentVersion, latestVersion) {
        let currentVersionInt = versionInt(currentVersion);
        let latestVersionInt = versionInt(latestVersion);

        return currentVersionInt >= latestVersionInt
   }

   const versionInt = function(version) {
       let version_num = version.replace('Version ', '')
       let version_str = version_num.replaceAll('.', '');
       return parseInt(version_str)
   };

   return (
      <div className="dashboard-body" id="dashboard-body">
         <AccountLogo account={accountDetails.dealer_number} accountName={accountDetails.name} />
           <div className="vertically-middle">
            <div className="h-auto justify-content-center align-items-center p-0 m-0">
               {loggedinUser &&
                  <>
                     {loggedinUser.account_up ?
                        <Link to="/sendNewSalesBroadcast" className="custom-margin-bottom-15">
                           <Menu
                              isFrom="dashboard"
                              anDelay='0s'
                              className="animate-one dashboard-icon"
                              alt="Sends New Sales Broadcast icon"
                              title={t(StringConstant.labelSendNewSalesBroadcast)}
                              src={icon1}
                           />
                        </Link>
                        :
                        <Menu
                           isFrom="dashboard"
                           anDelay='0s'
                           className="animate-one dashboard-icon"
                           alt="Sends New Sales Broadcast icon"
                           customMargin=" custom-margin-bottom-15"
                           title={t(StringConstant.labelSendNewSalesBroadcast)}
                           src={icon1}
                           styles={opacity}
                        />
                     }

                     {(loggedinUser.account_up && loggedinUser.approver) ?
                        <Link to="/previousPost" className="custom-margin-bottom-15">
                           <Menu
                              isFrom="dashboard"
                              anDelay='0.3s'
                              className="offset-4 animate-two dashboard-icon"
                              alt="Send Previous Sales Broadcast icon"
                              title={t(StringConstant.labelPreviousSalesBroadcast)}
                              src={icon2}
                           />
                        </Link>
                        :
                        <Menu
                           isFrom="dashboard"
                           anDelay='0.3s'
                           className="offset-4 animate-two dashboard-icon"
                           alt="Send Previous Sales Broadcast icon"
                           customMargin=" custom-margin-bottom-15"
                           title={t(StringConstant.labelPreviousSalesBroadcast)}
                           src={icon2}
                           styles={opacity}
                        />
                     }

                     {(loggedinUser.account_up && accountDetails.has_delivery_bay) ?
                        <div onClick={() => setShowModal(true)}>
                           <Menu
                              isFrom="dashboard"
                              anDelay='0.6s'
                              className="animate-three dashboard-icon"
                              alt="Send Delivery Broadcast Only"
                              title={t(StringConstant.labelSendDelivery)}
                              src={icon4}
                           />
                        </div>
                        :
                        <Menu
                           isFrom="dashboard"
                           anDelay='0.6s'
                           className="animate-three dashboard-icon"
                           alt="Send Delivery Broadcast Only"
                           customMargin=" custom-margin-bottom-15"
                           title={t(StringConstant.labelSendDelivery)}
                           src={icon4}
                           styles={opacity}
                        />
                     }
                  </>
               }

            </div>
         </div>
         {isAlertCameraWarning &&
         <Modal show={isAlertCameraWarning} handleClose={() => setIsAlertWarning(false)}>
            <div className="messageSection">
               <div className="textCenter">
                  <h5>{t(StringConstant.headingCameraIssue)}</h5>
                  <p>{t(StringConstant.textCameraIssue)}</p>
                  <div>
                     <label>
                        <input type="checkbox" value={isCameraWarning} onChange={() => {
                           setIsCameraWarning(!isCameraWarning);
                           localStorage.setItem("isDontShowCameraWarning", !isCameraWarning)
                        }} />
                        {t(StringConstant.labelDontShowAgain)}
                     </label>
                  </div>
                  <button className="btn buttonCls" onClick={() => setIsAlertWarning(false)}>Ok</button>
               </div>
            </div>
         </Modal>
         }
         {showModal &&
            <Modal show={showModal} handleClose={() => setShowModal(false)}>
               <h3 className="labelDeliverType">{t(StringConstant.labelChooseDeliveryType)}</h3>
               <Button   to="/sendNewSalesBroadcast" navData={{ isSendDeliveryBroadcast: true }} title={t(StringConstant.labelNewBroadcast)} btnClass="modalOptions" />
               <Button  to="/previousPost" navData={{ isSendDeliveryBroadcast: true }} title={t(StringConstant.labelFromPrevious)} btnClass="modalOptions" />
            </Modal>
         }
      </div>



   )
}

export default Dashboard
