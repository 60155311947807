import React, { useEffect, useState, useContext, Fragment } from 'react'
import './pptv.css'
import ListView from "../../components/ListView/listView"
import useRenderCount from "../../renderCountHook"
import Loader from "../../components/Loader/loader"
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import StringConstant from '../../stringConstant'
import { useTranslation } from "react-i18next";
import {Redirect} from 'react-router-dom';

const PPTV = (props) => {
    useRenderCount("PTTV")
    const [pptvList, setPPTVList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const { setMessage } = useContext(ToastContext);
    const { t } = useTranslation();

    //--API call for showing histroy post--//
    useEffect(() => {
        setShowLoader(true)
        const pptvList = async () => {
            const response = await apiRequest('playable_channels', undefined, undefined, setMessage);
            setShowLoader(false)
            if (response) {
                setPPTVList(response)
            }


        };
        pptvList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            {showLoader && <Loader />}
            <div id="container" className="container listview-body-wrapper history-body pad-btm center-data vertically-middle">
                <div className="pttv-container">
                    {pptvList.length > 0 && pptvList.map((e) => {
                        if( pptvList.length > 1) {
                            return (

                                <ListView to="/pttvDetail" data={e} key={e.id} isFrom="pttv"/>
                            )
                        }else{

                            return <Redirect to={{ pathname: '/pttvDetail' ,  state: { data: e, key: e.id, isFrom : 'pttv' }  }} />

                        }

                    })}
                    {pptvList.length === 0 &&
                        <p>{t(StringConstant.noDTVChannels)}</p>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default PPTV