import React, { useEffect, useState, useContext, Fragment, useRef } from 'react'
import './previousPost.css'
import ListView from "../../components/ListView/listView"
import useRenderCount from "../../renderCountHook"
import Loader from "../../components/Loader/loader"
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";

const PreviousPost = (props) => {
   useRenderCount("HISTORY")
   const [sendPreviousPostItem, setsendPreviousPostItem] = useState([]);
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();
   let isSendDeliveryBroadcast = null;

   if (props.location && props.location.state) {
      isSendDeliveryBroadcast = props.location.state.isSendDeliveryBroadcast
   }
   const postRef = useRef(0);
   //--- Get Post API ----//
   useEffect(() => {
      setShowLoader(true)
      const historyDetails = async () => {
         const response = await apiRequest('posts', undefined, undefined, setMessage);
         setShowLoader(false)
         if (response && response.posts.length > 0) {
            const approvedPosts = response.posts.filter(res => res.approved);
            setsendPreviousPostItem(approvedPosts)
         }
      };
      historyDetails()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <Fragment>
         {showLoader && <Loader />}
         <div id="container" className="container listview-body-wrapper history-body pad-btm">
            <div className="row" style={{ marginBottom: "10px" }}>
               {isSendDeliveryBroadcast ?
                  <>
                     {sendPreviousPostItem.length > 0 ? sendPreviousPostItem.map((e) => {
                        if (e.channel_id) {
                           const currentDate = new Date(e.created_at).getDate();
                           if (postRef.current !== currentDate) {
                              postRef.current = currentDate;
                              return (
                                 <Fragment key={e.id}>
                                    <div className="col-12 text-center mt-2 mb-2 his-date pl-0 pr-0">
                                       <p>{new Date(e.created_at).toDateString()}</p>
                                    </div>
                                    <ListView data={e} to={'/confirm&Submit'} isFrom='previousPost' />
                                 </Fragment>
                              )
                           }
                           return (
                              <ListView data={e} key={e.id} to={'/confirm&Submit'} isFrom='previousPost' />
                           )
                        } else {
                           return null
                        }
                     })
                        :
                        <div className="col-12 text-center mt-2 mb-2 pl-0 pr-0">
                           <p>{t(StringConstant.labelNoPostAvailable)}</p>
                        </div>
                     }
                  </>
                  :
                  <>
                     {sendPreviousPostItem.length > 0 ? sendPreviousPostItem.map((e) => {
                        const currentDate = new Date(e.created_at).getDate();
                        if (postRef.current !== currentDate) {
                           postRef.current = currentDate;
                           return (
                              <Fragment key={e.id}>
                                 <div className="col-12 text-center mt-2 mb-2 his-date pl-0 pr-0">
                                    <p>{new Date(e.created_at).toDateString()}</p>
                                 </div>
                                 <ListView data={e} to={'/confirm&Submit'} isFrom='previousPost' />
                              </Fragment>
                           )
                        }
                        return (
                           <ListView data={e} key={e.id} to={'/confirm&Submit'} isFrom='previousPost' />
                        )
                     })
                        :
                        <div className="col-12 text-center mt-2 mb-2 pl-0 pr-0">
                           <p>{t(StringConstant.labelNoPostAvailable)}</p>
                        </div>
                     }
                  </>
               }

            </div>

            {sendPreviousPostItem.length > 4 ?
                <div className="fadeBG"></div> : null}
         </div>

      </Fragment>
   )
}

export default PreviousPost