import React, { useState, useContext, useEffect } from 'react';
import './optionalPhoto.css'
import Button from "../../components/Button/button";
import StringConstant from '../../stringConstant';
import S3 from 'aws-s3';
import S3FileUpload from "react-s3";
import Loader from "../../components/Loader/loader";
import ToastContext from "../../Context/toastContext";
import imageCompression from 'browser-image-compression';
import { useTranslation } from "react-i18next";
import localForage from "localforage";
import apiRequest from "../../api";

const OptionalPhoto = (props) => {
   const { setMessage } = useContext(ToastContext);
   const [showLoader, setShowLoader] = useState(false);
   const [cameraImg, setCameraImg] = useState();
   const [s3ImageKey, setS3ImageKey] = useState();
   const navData = { ...props.location.state };
   const { t } = useTranslation();


   if (cameraImg) {
      navData["cameraImg"] = cameraImg;
      navData["cameraImgs3Key"] = s3ImageKey;
   }

   if (localStorage.getItem('cameraImg')) {
      navData["cameraImg"] = localStorage.getItem('cameraImg');
      navData["cameraImgs3Key"] = localStorage.getItem('cameraImgs3Key');
   }

    useEffect(() => {
        getCurrentAccountSettings();
    } );

   const logOut = () => {
      localStorage.removeItem("authenticated");
      localStorage.clear();
      sessionStorage.clear();
      localForage.removeItem('listCarMake').then(function () {
         // Run this code once the database has been entirely deleted.
         console.log('Database is now empty.');
      }).catch(function (err) {
         // This code runs if there were any errors
         console.log(err);
      });
      props.history.replace("/");
   };

    const getCurrentAccountSettings = async () => {
        const response = await apiRequest('accounts/current', 'GET', undefined, setMessage);
        setShowLoader(false);
        if (response) {
            localStorage.setItem("accountDetails", JSON.stringify(response));
        }
    };

   const handleUpload = async e => {
      let userData = JSON.parse(localStorage.getItem('authenticated'));

      if(userData.s3_config) {
         let S3_CONFIG = { bucketName: userData.s3_config.bucket,
            dirName: userData.id,
            region: userData.s3_config.region,
            secretAccessKey: userData.s3_config.secret_access_key,
            accessKeyId: userData.s3_config.access_key_id };
         if (e.target.files[0] && (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg")) {
            setShowLoader(true);
            let fileUrl = URL.createObjectURL(e.target.files[0]);
            let options = {
               maxSizeMB: 0.08,
               maxWidthOrHeight: 1920,
               useWebWorker: true,
               exifOrientation: 1,
            }
            const compressedFile = await imageCompression(e.target.files[0], options);
            // TODO add this back to fix testing const S3Client = new S3(S3_CONFIG);
            S3FileUpload
                .uploadFile(compressedFile, S3_CONFIG)
                .then(data => {
                   setShowLoader(false)
                   if (data) {
                      setCameraImg(fileUrl);
                      setS3ImageKey(data.key);
                      navData["cameraImg"] = data.location;
                      navData["cameraImgs3Key"] = data.key;
                      navData['imageUrl'] = data.location;
                      props.history.push({ pathname: "/adjustPhoto", state: { ...navData } })

                   }
                })
                .catch(err => {
                   setShowLoader(false)
                   setMessage({ head: "Message", body: `Network Error.` });
                })


         } else {
            setMessage({ head: "Message", body: `Please upload a valid image.` });
         }
      } else {
         logOut();
      }
   };

   const handleSkipImage = () => {
      localStorage.removeItem('cameraImg');
      localStorage.removeItem('cameraImgs3Key');
      navData['cameraImg'] = '';
      navData['cameraImgs3Key'] = '';
      props.history.push({ pathname: "/enterData", state: { ...navData } })
   }

   return (
      <div className="oprtionalPhoto-wrapper vertically-middle">
         {showLoader && <Loader />}

         {
            localStorage.getItem('cameraImg') &&
            <Button navData={navData} to="/optional-photo-view" title={t(StringConstant.labelPreviewUploadedImg)} />
         }

         <div className="optPhoto-btn choose-photo">
            <div className="optionalPhoto"
               style={{ background: 'linear-gradient(135deg,#9a0915,rgba(255,15,71,.88))', color: '#fff', fontSize: '16px', padding: '8px' }} >
               <label> {t(StringConstant.labelChoosePhoto)}
                  <input
                     type="file"
                     accept=".png,.jpeg,.jpg"
                     onChange={handleUpload}
                  />
               </label>
            </div>
         </div>
         {
            props.location.state && props.location.state.carColor &&
            <div className="optPhoto-btn skip-btn">
               <Button btnClick={() => handleSkipImage()} title={t(StringConstant.labelSkip)} style={{ width: '70% !important' }} />
            </div>
         }
      </div >
   )
}

export default OptionalPhoto
