import React, { useState, useContext, useEffect } from "react";
import "./confirmSubmit.css";
import "./adjustPhoto.css";
import Button from "../../components/Button/button";
import Loader from "../../components/Loader/loader";
import ToastContext from "../../Context/toastContext";
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { faSearchMinus } from "@fortawesome/free-solid-svg-icons";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import S3 from 'aws-s3';
import S3FileUpload from "react-s3";

const AdjustPhoto = (props) => {
    const [showLoader, setShowLoader] = useState(false);
    const { setMessage } = useContext(ToastContext);
    const { cameraImg, cameraImgs3Key } = props.location.state || { cameraImg: null, cameraImgs3Key: null };
    const { t } = useTranslation();
    const [cropper, setCropper] = useState(null);
    const [cropperInitialized, setCropperInitialized] = useState(false);
    const [modifiedS3Key, setModifiedS3Key] = useState();
    const [modifiedS3Image, setModifiedS3Image] = useState();
    const [portrait, setPortrait] = useState(null);
    const [historyData] = useState(localStorage.getItem('enterData') ? JSON.parse(localStorage.getItem('adjustPhoto')) : null);
    const navData = { ...props.location.state };
    const alternate_landscape_ratio = 975 / 1110;
    const alternate_portrait_ratio = 1091 / 875;
    const landscape_ratio = 680 / 482;
    const portrait_ratio = 420 / 610;

    useEffect(() => {
        if(!cameraImg && !cameraImgs3Key) {
            // The page was navigated to without img params. Probably a user bookmarking page or reloading at a later date. Let's redirect back to dashboard
            props.history.replace("/dashboard")
        }

        if (historyData) {
            setModifiedS3Image(historyData.modifiedS3Image);
            setModifiedS3Key(historyData.modifiedS3Key);

            localStorage.removeItem('adjustPhoto');
        }
    }, [historyData]);

    const rotateRight = function() {
        cropper.rotate(90);
        setAspectRatio(cropper);
    };

    const rotateLeft = function() {
        cropper.rotate(-90);
        setAspectRatio(cropper);
    };

    const zoomIn = function() {
        cropper.zoom(0.1);
    };

    const zoomOut = function() {
        cropper.zoom(-0.1);
    };

    const canvasDimensions = function () {
        let canvas = document.querySelector('.cropper-canvas');
        return {height: canvas.offsetHeight, width: canvas.offsetWidth};
    };

    const undoImageChanges = function() {
        cropper.reset();
        setAspectRatio(cropper);
    };

    const isPortrait = function() {
        let dimensions = canvasDimensions();
        let isPortrait = dimensions.height > dimensions.width;
        setPortrait(isPortrait);
        return isPortrait;
    };

    const setAspectRatio = function(cropper) {
        let canvas = document.querySelector('.cropper-canvas');
        if(canvas) {
            let accountDetails = JSON.parse(localStorage.getItem("accountDetails"));
            let portrait = isPortrait();

            if(accountDetails.alternate_share_image) {
                if(portrait) {
                    cropper.setAspectRatio(alternate_portrait_ratio);
                } else {
                    cropper.setAspectRatio(alternate_landscape_ratio);
                }
            } else {
                if(portrait) {
                    cropper.setAspectRatio(portrait_ratio);
                } else {
                    cropper.setAspectRatio(landscape_ratio);
                }
            }
        }
    };

    const saveImageChanges = function() {
        cropper.getCroppedCanvas().toBlob(function(blob) {
            blob.name = 'modified_' + cameraImgs3Key;
            setShowLoader(true);
            let userData = JSON.parse(localStorage.getItem('authenticated'));
            let S3_CONFIG = { bucketName: userData.s3_config.bucket,
                region: userData.s3_config.region,
                secretAccessKey: userData.s3_config.secret_access_key,
                accessKeyId: userData.s3_config.access_key_id };
            S3FileUpload
                .uploadFile(blob, S3_CONFIG)
                .then(data => {
                    if (data) {
                        setModifiedS3Image(null);
                        setModifiedS3Image(data.location);
                        setModifiedS3Key(data.key);
                        next(data.location, data.key);
                    }
                })
                .catch(err => {
                    setShowLoader(false);
                    setMessage({ head: "Message", body: `Network Error.` });
                })
        });
    };

    const next = (modifiedS3Image, modifiedS3Key) => {
        if(modifiedS3Image && modifiedS3Key) {
            navData["cameraImg"] = modifiedS3Image;
            navData["cameraImgs3Key"] = modifiedS3Key;
            navData["modifiedS3Image"] = modifiedS3Image;
            navData["modifiedS3Key"] = modifiedS3Key;
            navData['imageUrl'] = modifiedS3Image;
        }

        navData['photoIsPortrait'] = portrait;
        props.history.push({ pathname: "/enterData", state: { ...navData } })
    };

    const assignCropper = function(newCropper) {
        setCropper(newCropper);
        newCropper.element.addEventListener('ready', function() {
            setShowLoader(false);
            setAspectRatio(newCropper);
            setCropperInitialized(true);
        });
    };

    return (
        <div id="container" className="container confirmSubmit-body">
            <div className="adjustPhoto">
                <div className="banner">
                    <div className="showImg" id="imageBox">
                        <Cropper
                            src={modifiedS3Image ? modifiedS3Image : cameraImg}
                            style={{height: 270, width: '100%'}}
                            // Cropper.js options
                            viewMode={0}
                            guides={false}
                            background={false}
                            onInitialized={assignCropper}
                            dragMode={'none'}
                            modal={false}
                            zoomable={true}
                            autoCrop={true}
                            autoCropArea={1}
                        />
                        <div className="row rotate-row">
                            <div className="col text-center">
                                <div className="btn-group btn-dark btn-rotation" role="group" aria-label="Basic example">
                                    <button className="btn btn-dark" onClick={zoomIn} disabled={!cropperInitialized}>
                                        <FontAwesomeIcon icon={faSearchPlus}/>
                                    </button>
                                    <button className="btn btn-dark" onClick={zoomOut} disabled={!cropperInitialized}>
                                        <FontAwesomeIcon icon={faSearchMinus}/>
                                    </button>
                                    <button className="btn btn-dark" onClick={rotateLeft} disabled={!cropperInitialized}>
                                        <FontAwesomeIcon icon={faUndo}/>
                                    </button>
                                    <button className="btn btn-dark" onClick={rotateRight} disabled={!cropperInitialized}>
                                        <FontAwesomeIcon icon={faRedo}/>
                                    </button>
                                    <button className="btn btn-dark" onClick={undoImageChanges} disabled={!cropperInitialized}>
                                        Undo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='next_fixed_btn'>
                    <Button btnClick={saveImageChanges} title={t(StringConstant.labelNext)} disabled={!cropperInitialized} />
                </div>
                <br />
            </div>
            {showLoader && <Loader />}
        </div>
    )
};

export default AdjustPhoto;
