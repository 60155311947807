import React from 'react'
import phoneImage from '../../Assets/phone.svg'
import envelopeImage from '../../Assets/mail.svg';

const ContactModule = ({ aboutTitle, alt, hrefemail, hreftelephone }) => {
  const hrefmail = "mailto:" + hrefemail
  const hreftel = "tel:" + hreftelephone
  return (
    <div className="module buttonCls">
      <div className="row text-center">
        <div className="col-12">
          <a href={hrefmail} className="desktopView-email">
            <img src={envelopeImage} className="email" alt={alt} />
            <p>{hrefemail}</p>
          </a>
          <a href={hreftel} className="desktopView-telephone">
            <img src={phoneImage} className="telephone" alt={alt} />
            <p>{hreftelephone}</p>
          </a>
          <p className="about-title">{aboutTitle}</p>
        </div>
      </div>
    </div>
  )
}

export default ContactModule