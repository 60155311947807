import React, { useEffect, useState, Fragment } from "react";
import useRenderCount from "../../renderCountHook";
import DropDown from "../../components/DropDown/dropdown";
import Loader from "../../components/Loader/loader"
import './videoBroadcast.css'
import Button from "../../components/Button/button";
import VideoImg from "../../Assets/background-123.jpg"
import StringConstant from "../../stringConstant";
import { useTranslation } from "react-i18next";

const EnterData = () => {
    useRenderCount("ENTER DATA")

    const [customerTitles, setCustomerTitles] = useState([]);
    const [approvers, setApprovers] = useState([]);
    const [approver, setApprover] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const { t } = useTranslation();

    return (
        <Fragment>
            {showLoader && <Loader />}
            <div className="select-vehicle enter-data-body videoBroadcast-body" id="select-vehicle-body videoBroadcast-body pad-btm">
                <div className="ddList">
                    <div className="h-100 justify-content-center align-items-center">
                        <div className="">
                            <DropDown ddclassName="box-7" list={customerTitles}
                                onChange={e => { }}
                            />
                            <DropDown ddclassName="box-8" list={approvers}
                                onChange={e => {
                                    setApprover(JSON.parse(e.target.value));
                                }}
                            />
                        </div>
                    </div>
                    {approver &&
                        <div className="showImg">
                            <img src={VideoImg} alt="choose-img" />
                        </div>
                    }
                    {(customerTitles && approver) && <Button btnClick={() => { }} title={t(StringConstant.labelNext)} />}}
            </div>
            </div>
        </Fragment>
    )
}

export default EnterData