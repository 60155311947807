import React, { useState, useEffect } from 'react';
import MainApp from './pages/app';
import PortraitImg from './Assets/l_to_p.png';
import { BrowserRouter } from "react-router-dom";
import './App.css';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import StringConstant from "./stringConstant";

const Portrait = () => {
  return (
    <div className='portrait-mode'>
      <img src={PortraitImg} alt='to_portrait' className='img-fluid' />
      <h1>Rotate your device to Portrait Mode</h1>
      <p>Landscape is not supported yet. Please rotate your device to Portrait mode for the best experience.</p>
    </div>
  );
}

function initializeReactGA() {
    ReactGA.initialize('UA-82332892-2', { testMode: process.env.NODE_ENV === 'test' });
    ReactGA.pageview('/');
}

function App() {
  if(window.location.hostname !== 'localhost') {
      let production_url = "https://389cace8678d43b584328f70924939a2@o224042.ingest.sentry.io/5209910";
      let staging_url = "https://5a7eba107afa4a82bbc895b07f48bf5b@o224042.ingest.sentry.io/5630855";
      let sentry_url = isProduction() ? production_url : staging_url;
      let env = isProduction() ? 'production' : 'staging';
      Sentry.init({dsn: sentry_url,
                           ignoreErrors: ['Cannot update a service worker with a requested script URL whose newest worker has a different script URL',
                                          'TypeError: The network connection was lost.', 'TypeError: cancelled',
                                          'TypeError: Failed to fetch',
                                          'TypeError: Cannot read property \'addEventListener\' of undefined',
                                          'The request timed out',
                                          'The Internet connection appears to be offline.',
                                          'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type'],
                           environment: process.env.NODE_ENV});
      Sentry.setContext('PWA Version', { version: StringConstant.labelAppVersion } );
      if(localStorage.getItem('authenticated')) {
          let userData = JSON.parse(localStorage.getItem('authenticated'));
          Sentry.setUser({ email: userData.email, id: userData.id } );
      }
  }
  initializeReactGA();
  const [isPortrait, setIportrait] = useState(true);
  useEffect(() => {
      function doOnOrientationChange() {
        if( window.location.pathname != '/optionalPhoto' ) {
        if (window.orientation % 180 === 0 || window.screen.orientation % 180 === 0) {
          setIportrait(true);
        } else {
          setIportrait(false);
        }
      }
    }
    window.addEventListener('orientationchange', doOnOrientationChange);
  }, [isPortrait]);

  return (
    <BrowserRouter>
      {isPortrait ? <MainApp /> : <Portrait />}
    </BrowserRouter>
  );
}

function isProduction() {
    return window.location.hostname === 'abn.live';
}

export default App;
