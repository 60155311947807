import React, { useEffect, useState, useContext, Fragment } from 'react'
import './history.css'
import useRenderCount from "../../renderCountHook"
import Loader from "../../components/Loader/loader"
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import { Link } from 'react-router-dom';
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";
import Img from 'react-image';
import LazyLoad from 'react-lazyload';

const History = (props) => {
   useRenderCount("HISTORY")
   const loginUserData = localStorage.getItem('authenticated') ? JSON.parse(localStorage.getItem('authenticated')) : null
   const [historyItem, setHistoryItem] = useState([]);
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   let [newPost, setNewPost] = useState(0);
   let [approvedPost, setApprovedPost] = useState(0);
   let [submittedPost, setSubmittedPost] = useState(0);
   const { t } = useTranslation();
   const user = localStorage.getItem("authenticated") ? JSON.parse(localStorage.getItem("accountDetails")) : null;


   //--API call for showing histroy post--//
   useEffect(() => {
      setShowLoader(true)
      const historyDetails = async () => {
         const response = await apiRequest('posts', undefined, undefined, setMessage);
         if (response) {
            setHistoryItem(response.posts);
            if (response.statuses && response.statuses.length > 0) {
               for (let i = 0; i < response.statuses.length; i++) {
                  if (response.statuses[i].status === "New") {
                     setNewPost(response.statuses[i].count)
                  } else if (response.statuses[i].status === "Approved") {
                     setApprovedPost(response.statuses[i].count)
                  } else if (response.statuses[i].status === "Unapproved Submitted") {
                     setSubmittedPost(response.statuses[i].count)
                  }
               }
            }
         }
         setShowLoader(false)
      };
      historyDetails()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])



   return (

      <Fragment>
         {showLoader && <Loader />}
         <div className="wrapper-history">
            <div className="fixed-top">
               <div className="profile">
                  {`Posts for ${user.name}`}
               </div>

               <div className="pro-data">
                  <div>{newPost}<span>{t(StringConstant.labelNewTab)}</span></div>
                  <div>{submittedPost}<span>{t(StringConstant.labelSubmitted)}</span></div>
                  <div>{approvedPost}<span>{t(StringConstant.labelApproved)}</span></div>
               </div>
            </div>
            <div className="thumb">

               <ul>
                  {historyItem.length > 0 &&
                     historyItem.map(i => {
                        let lblColor = 'red-lbl';
                        if (i.status === "New") {
                           lblColor = 'blue-lbl'
                        } else if (i.status === "Approved") {
                           lblColor = 'green-lbl'
                        } else if (i.status === "Submitted") {
                           lblColor = 'orange-lbl'
                        }
                        return (
                           <li key={`post_${i.id}`}>

                              <Link to={{ pathname: '/post', state: { data: i } }}>
                                 <div className="relative">
                                    <span className={`label ${lblColor}`}>{i.status}</span>
                                    <div className="img-wrapper">
                                       <LazyLoad height={50} overflow>
                                          <img src={i.photo.large} />
                                       </LazyLoad>
                                    </div>
                                    <div className="dt-thumb">{new Date(i.created_at).toLocaleDateString("en-US").slice(0, -5)}</div>
                                    <div className="txt-thumb">{i.customer_name_with_title}</div>
                                 </div>
                              </Link>


                           </li>
                        )
                     })
                  }
               </ul>

            </div>
            {historyItem.length > 6 ?
               <div className="fadeBG"></div> : null}
         </div>
      </Fragment>
   )
}

export default History