import React, { useEffect, useState, useContext } from 'react'
import './singleUser.css'
import Button from "../../components/Button/button";
import logo from '../../Assets/single-user.svg'
import Loader from "../../components/Loader/loader"
import ToastContext from "../../Context/toastContext";
import apiRequest from "../../api";
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";
import { UserData } from '../../userData'

const SingleUser = (props) => {
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const user = (props.location && props.location.state) ? props.location.state.data : null;
   const [loggedInUser, setLoggedInUser] = useState(null);
   const [isAutoApprove, setIsAutoApprove] = useState(user ? user.auto_approve_posts : false)
   const [isUserActive, setIsUserActive] = useState((user && user.status === "New") ? "Inactive" : user ? user.status : null)
   const [isSiteUser, setIsSiteUser] = useState(user ? user.access_level : null)
   const { t } = useTranslation();

   //--API call for update user--//
   const updateUser = async () => {
      setShowLoader(true)
      const body = {
         "access_level": isSiteUser,
         "status": isUserActive,
         "auto_approve_posts": isAutoApprove
      };
      const response = await apiRequest(`users/${user.id}`, "PUT", body, setMessage);
      if (response) {
         setMessage({ head: "Message", body: t(StringConstant.labelUserUpdateSuccess) });
         props.history.goBack();
      }
      setShowLoader(false)
   };

   useEffect(() => {
      new UserData(setMessage, setShowLoader, props.history).maybeFetchData(setLoggedInUser);
   }, []);

   return (
      <div id="container" className="container single-user-body">
         {user &&
            <div className="single-user">
               <div className="banner">
                  <img src={logo} alt="logo" />
               </div>
               <h3 className="name p-2">{user.first_name +' '+ user.last_name}</h3>
               <div className="actions">
                  <div className="title">{user.email}</div>



               </div>
               {(loggedInUser && loggedInUser.admin) &&
                  <React.Fragment>

                  <div className="col-8 button-wrapper">

                     <div className="auto-approved container p-2 m-2">
                        <p className="float-left" >{t(StringConstant.labelAutoApprovePost)}</p>
                        <div className="checkbox switcher">
                           <label htmlFor="test3">
                              <input type="checkbox" id="test3" checked={isAutoApprove} onChange={() => {
                                 if (isSiteUser === "Manager") {
                                    setIsAutoApprove(!isAutoApprove)
                                 } else {
                                    setIsAutoApprove(false);
                                    setMessage({ head: "Message", body: t(StringConstant.errMessageForUpdateAutoApprove) });
                                 }
                              }} />
                              <span><small></small></span>
                           </label>
                        </div>
                     </div>

                  </div>



                     <div className="container">
                        <div className="col-12 tab-btn-wrapper">
                           <div className="col-6 tab-btn-inner float-left d-inline-block p-0">
                              <Button btnClass={isUserActive === "Accepted" ? "btn-active btn-border" : "btn-border"} btnClick={() => setIsUserActive("Accepted")} title={t(StringConstant.labelAccepted)} />
                           </div>
                           <div className="col-6 tab-btn-inner float-left d-inline-block p-0">
                              <Button btnClass={isUserActive === "Inactive" ? "btn-active btn-border" : "btn-border"} btnClick={() => setIsUserActive("Inactive")} title={t(StringConstant.labelInactive)} />
                           </div>
                           <div className="col-6 tab-btn-inner float-left d-inline-block p-0">
                              <Button btnClass={isSiteUser === "User" ? "btn-active btn-border" : "btn-border"} btnClick={() => { setIsSiteUser("User"); setIsAutoApprove(false) }} title={t(StringConstant.labelUser)} />
                           </div>
                           <div className="col-6 tab-btn-inner float-left d-inline-block p-0">
                              <Button btnClass={isSiteUser === "Manager" ? "btn-active btn-border" : "btn-border"} btnClick={() => setIsSiteUser("Manager")} title={t(StringConstant.labelManager)} />
                           </div>
                        </div>

                     </div>
                     <br />
                     <Button btnClick={() => updateUser()} title={t(StringConstant.labelSaveUser)} />
                  </React.Fragment>
               }
            </div>

         }
         {showLoader && <Loader />}
      </div>
   )
}

export default SingleUser