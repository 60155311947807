import React, { useContext, useEffect, useState } from "react";
import "./newUserStatus.css"
import Button from "../../components/Button/button";
import useRenderCount from "../../renderCountHook"
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext";
import StringConstant from "../../stringConstant";
import { useTranslation } from "react-i18next";

const NewUserStatus = props => {
   useRenderCount("New User Status")
   const [loggedinUser, setLoggedinUser] = useState(null);
   const [isFrom, setIsFrom] = useState(null);
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();
   let message = <p className="success-msg">{t("A message with a confirmation link has been sent to your email address.")}<small>{t("Please open the link to activate your account.")}</small></p>
   if (props.history.location.state && props.history.location.state.message) {
      message = <p className="success-msg">{props.history.location.state.message}</p>
   }
   useEffect(() => {
      if (props.history.location.state) {
         setLoggedinUser(props.history.location.state.email)
         setIsFrom(props.history.location.state.isFrom)
      }
   }, [props])

   const resendConfirmation = async () => {
      const response = await apiRequest('resend_confirmation', "PUT", { email: loggedinUser }, setMessage);
      if (response) {
         setMessage({ head: "Message", body: t("Instructions were resent to your email id") })
      }
   };

   return (
      <div className="userStatus-body" id="userStatus-body">
         <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
               <div className="col-12">
                  {message}
                  <div className="col-12">
                     {isFrom && isFrom === "signup" &&
                        <Button btnClick={resendConfirmation} title={t(StringConstant.labelResendConfirmation)} />
                     }
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default NewUserStatus