import React, { useEffect, useState, useContext, Fragment } from "react";
import "./help.css";
import safari from '../../Assets/safari-icon.png';
import useRenderCount from "../../renderCountHook"
import apiRequest from "../../api";
import Loader from "../../components/Loader/loader"
import ContactModule from "../../components/ContactModule/contactModule";
import ToastContext from "../../Context/toastContext";
import StringConstant from '../../stringConstant'
import { useTranslation } from "react-i18next";
import {deviceDetect, isIOS, isMobile, isSafari, isChrome, browserName} from "react-device-detect";
import addressInfoImage from "../../Assets/abn-badge.png";



const Help = (props) => {
   useRenderCount("HELP")
   const [compDetails, setCompDetails] = useState();
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();

   //--API call for company details//
   useEffect(() => {
      setShowLoader(true)
      const comapnyDetails = async () => {
         const response = await apiRequest('company', undefined, undefined, setMessage);
         setShowLoader(false)
         if (response) {
            setCompDetails(response)
         }
      };
      comapnyDetails()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const company = compDetails || {};



   return (
      <Fragment>
         {showLoader && <Loader />}
         <div id="container" className="container help-body">
            <div className="about">
               <br />

               { (isIOS && isChrome) ?


                   <div class={"img_header"}>
                   <img src={safari} alt="safari" />
                   <h4 className="name header-body">Please use the Safari web browser to install this application on your iPhone.</h4>
                  </div>
                   :

                   <h2></h2>

               }

               <div className="follow-phone single-contact desc">
                  <span>Support Contacts</span>
                  <ContactModule alt="Contact Support" hrefemail={company.support_email} hreftelephone={company.support_phone} />
                  <div className="title">App {t(StringConstant.labelAppVersion)}</div>
               </div>

            </div>
         </div>
      </Fragment>
   )
}

export default Help;