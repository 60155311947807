import React, { Fragment, useContext, useState } from "react";
import { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input'
import TextInput from "../../components/TextInput/textInput";
import Button from "../../components/Button/button";
import ToastContext from "../../Context/toastContext";
import apiRequest from "../../api";
import { withRouter } from "react-router";
import StringConstant from '../../stringConstant'
import { useTranslation } from "react-i18next";

const SignUp = ({ email, setShowLoader, askEmail, history }) => {
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [mobilePhone, setMobilePhone] = useState('');
   const [dealerNum, setDealerNum] = useState('');
   const [password, setPassword] = useState('');
   const [confPassword, setConfPassword] = useState('');
   const [dealerName, setDealerName] = useState("");
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();
   let formattedMobilePhone;

   //--API call for verify dealer--//
   const verifyDealer = async () => {
      if (dealerNum) {
         setShowLoader(true);
         const response = await apiRequest(`accounts/check_dealer_number/?dealer_number=${dealerNum}`, undefined, undefined, setMessage);
         setShowLoader(false)
         if (response) {
            if (response.exists) {
               setDealerName(response.name)
            } else {
               setMessage("Dealer does not exists")
               setDealerName("")
            }
         }
      }
   };
   //--Signup form validation--//
   const validateForm = () => {
      let valid = true;
      if (!firstName.trim()) {
         valid = false;
         setMessage("Enter First Name")
      } else if (!lastName.trim()) {
         valid = false;
         setMessage("Enter Last Name")
      } else if (!dealerNum.trim()) {
         valid = false;
         setMessage("Enter Dealer Number")
      } else if (!password.trim()) {
         valid = false;
         setMessage("Enter Password")
      } else if (!confPassword.trim()) {
         valid = false;
         setMessage("Enter Confirm Password")
      }
      else if(mobilePhone) {
         if(isValidPhoneNumber(mobilePhone)) {
            setMessage(false);
            formattedMobilePhone = formatPhoneNumber(mobilePhone);
         } else {
            valid  = false;
            setMessage(`${mobilePhone} is not a valid US phone no.`);
            return;
         }
      }


      return valid;
   };
   //--API call for user signup--//
   const doSignUp = async () => {
      const validForm = validateForm();
      if (validForm) {
         setShowLoader(true);
         const body = {
            "first_name": firstName,
            "last_name": lastName,
            "mobile_phone": formattedMobilePhone,
            "dealer_number": dealerNum,
            "email": email,
            "password": password,
            "password_confirmation": confPassword
         };
         const response = await apiRequest("users", "POST", body, setMessage);
         setShowLoader(false);
         if (response) {
            setShowLoader(false)
            history.replace("/newUserStatus", { email, isFrom: 'signup' })
         }
      }
   };

   return (
      <Fragment>
         <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12">
               <p style={{ color: '#ffffff' }}>{dealerName}</p>
               <TextInput name="first-name" title={t(StringConstant.labelFname)} type="text"
                  value={firstName}
                  onChange={(e) => {
                     setMessage(false)
                     setFirstName(e.target.value)
                  }}
               />
               <TextInput name="last-name" title={t(StringConstant.labelLname)} type="text"
                  value={lastName}
                  onChange={(e) => {
                     setMessage(false)
                     setLastName(e.target.value)
                  }}
               />
               <TextInput name="dealer-number" title={t(StringConstant.labelDealerNumber)} type="number"
                  value={dealerNum}
                  onChange={(e) => {
                     setMessage(false)
                     setDealerNum(e.target.value)
                  }}
                  onBlur={verifyDealer}
               />
               <TextInput name="mobile-phone" title={t(StringConstant.labelMobile)} type="tel"
                          value={mobilePhone}
                          onChange={setMobilePhone}
               />
               <TextInput name="user-password" title={t(StringConstant.labelPassword)} type="password"
                  value={password}
                  onChange={(e) => {
                     setMessage(false)
                     setPassword(e.target.value)
                  }}
               />
               <TextInput name="user-conf-password" title={t(StringConstant.labelConfirmPassword)} type="password"
                  value={confPassword}
                  onChange={(e) => {
                     setMessage(false)
                     setConfPassword(e.target.value)
                  }}
               />
               <div className="col-12">
                  <Button title="SignUp" btnClick={doSignUp} />
               </div>
               <div className="password-info">
                  <div className="pass-info-up">
                     <p>{t(StringConstant.labelPasswordFor)} </p><span>{email}</span>
                     <Button btnClick={askEmail} title={t(StringConstant.labelNotThisEmailId)} btnClass="for-pass text-center not-email" />
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   )
}

export default withRouter(SignUp)