import apiRequest from "./api";
import localForage from "localforage";

export class UserData {
    data;
    setMessage;
    setShowLoader;
    history;

    constructor(setMessage, setShowLoader, history) {
        this.setMessage = setMessage;
        this.setShowLoader = setShowLoader;
        this.history = history;
        let json_data = localStorage.getItem('authenticated');
        if(json_data) {
            this.data = JSON.parse(json_data);
        }
    }

    async maybeFetchData(setter) {
        if(this.data && this.isDataExpired()) {
            await this.maybeRefreshData();
        }
        if(setter) {
            setter(this.data);
        }
        return this.data;
    }

    async maybeRefreshData() {
        this.setShowLoader(true);
        const response = await apiRequest("users/" + this.data.id, "GET", undefined, this.setMessage);
        if (response) {
            if (response.error) {
                this.setShowLoader(false);
                this.history.replace("/newUserStatus", { message: response.responseObject.errors[0], isFrom: 'login' })
            } else {
                let oldUser = JSON.parse(localStorage.getItem("authenticated"));
                let updatedUser = Object.assign({}, oldUser, response);
                await localStorage.setItem("authenticated", JSON.stringify(updatedUser));
                this.data = response;

                this.setShowLoader(false);
            }
        } else {
            this.logOut();
        }
        this.setShowLoader(false);
    }

    isDataExpired() {
        return Date.now() > this.getExpirationDate();
    }

    getExpirationDate() {
        return Date.parse(this.data.expiration_date);
    }

    logOut() {
        localStorage.removeItem("authenticated");
        localStorage.clear();
        sessionStorage.clear();
        localForage.removeItem('listCarMake').then(function () {
            // Run this code once the database has been entirely deleted.
            console.log('Database is now empty.');
        }).catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
        });
        this.history.replace("/");
    };
}