import React, { useState, useEffect, Fragment, useContext } from "react";
import ToastContext from "../../Context/toastContext";
import apiRequest from "../../api";
import "./users.css"
import ListView from "../../components/ListView/listView"
import Loader from "../../components/Loader/loader"

const Users = props => {
   const [filterValue, setFilterValue] = useState('active');
   const [users, setUsers] = useState([]);
   const [filteredUsers, setFilteredUsers] = useState([]);
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const loggedInUser = localStorage.getItem('authenticated') ? JSON.parse(localStorage.getItem('authenticated')) : null

   useEffect(() => {
      setShowLoader(true)
      const fetchUsers = async () => {
         const response = await apiRequest('users', undefined, undefined, setMessage);
         setShowLoader(false)
         if (response) {

            response.sort(function(a, b){
               return b.event_count - a.event_count;
            });

            setUsers(response)
            updateFilterValues('active', response);
         }
      };
      fetchUsers()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const updateFilterValues = (filterValue, userList) => {
      if(filterValue === 'active') {
         setFilteredUsers(userList.filter(user => user.is_inactive === false));
      }
      else if(filterValue === 'inactive') {
         setFilteredUsers(userList.filter(user => user.is_inactive === true));
      }
      else {
         setFilteredUsers(userList);
      }
   }

   return (
      <Fragment>
         {showLoader && <Loader />}
         <div id="container users-body-id" className="container listview-body-wrapper users-body pad-btm">
            <div className="row">
               <div className="col-md-12">
                  <div className="user-filter">
                     <label>Show :</label>
                     <select value={filterValue}
                             onChange={e => {
                                setFilterValue(e.target.value);
                                updateFilterValues(e.target.value, users);
                              }}
                     >
                        <option value='all'>All</option>
                        <option value='active'>Active</option>
                        <option value='inactive'>Inactive</option>
                     </select>
                  </div>
               </div>
               <br />
               <br />
            </div>
            <div className="row">
               {filteredUsers.map(user => {
                  return (
                     <ListView
                        key={user.id}
                        data={user}
                        from="USERLIST"
                        to={(user.id === loggedInUser.id) ? "/myProfile" : `/singleUser/${user.first_name}-${user.last_name}`}
                     />
                  )
               })}
            </div>

            {users.length > 4 ?
                <div className="fadeBG"></div> : null}

         </div>

      </Fragment>
   )
};

export default Users;
