export default {
    labelAppVersion: "Version 2.1.2",
    labelGetStarted: "Get Started",
    labelHelp: "Help",
    labelForgetPassword: "Forgot Password?",
    labelEnterEmail: "Enter Your Email Address",
    labelSubmit: "Submit",
    labelNext: "Next",
    labelModel: "Enter Model",
    labelStyle: "Enter Style",
    labelNewTab: "New",
    labelPreOwnedTab: "Pre Owned",
    labelFname: "First Name",
    labelLname: "Last Name",
    labelMobile: "Mobile Phone Number",
    labelDealerNumber: "Dealer Number",
    labelPassword: "Password",
    labelConfirmPassword: "Confirm Password",
    labelPasswordFor: "Password For -",
    labelNotThisEmailId: "Not this email Id?",
    labelEnterYourPassword: "Enter Your Password",
    labelSuccessPasswordChange: "Password change email sent to the email  provided",
    labelShowUser: "Show Users",
    labelMyProfile: "My Profile",
    labelSwitchAccount: "Switch Account",
    labelAccountStatus: "Account Status",
    labelPPTV: "PTTV",
    labelAbnInfo: "ABN Info",
    labelResetPassword: "Reset Password",
    labelRefreshUserDetail: "Refresh",
    labelReportBug: "Report a Bug",
    labelLogout: "Logout",
    labelSuccessNetwork: "The Account network is up and running",
    labelErrorNetwork: "The Account network is not running",
    labelAction: "Action",
    labelDelete: "Delete",
    labelSubmitForApproval: "Submit for Approval",
    labelDuplicatePost: "Duplicate Post",
    labelReportThisUser: "Report this user",
    labelSendNewSalesBroadcast: "Send New Sales Broadcast",
    labelPreviousSalesBroadcast: "Send Previous Sales Broadcast",
    labelSendVideo: "Send Videos",
    labelSendDelivery: "Send Delivery Broadcast Only",
    labelEnterFname: "Enter your first name",
    labelEnterLname: "Enter your last name",
    labelEnterMobile: "Enter your mobile phone number",
    labelPostRetentionDay: "Post History Retention Days",
    labelPostRetentionDayError: "History retention days must be greater than or equal to 7.",
    labelContactDetails: "Contact Details",
    labelRam: "RAM",
    labelRamSupervisor: "RAM Supervisor",
    labelBillingContact: "Billing Contact",
    labelCopyright: `Copyright ${new Date().getFullYear()} - Automotive Broadcasting Network`,
    labelChoosePhoto: "Choose Photo",
    labelSkip: "Skip",
    labelCusLastName: "Enter Customer Last Name",
    labelEmail: "Enter email",
    labelPhone: "Enter phone no.",
    labelAutoApproved: "Auto Approved",
    labelCongMsg: "Congratulations on the purchase of your",
    labelSendNow: "Send Now",
    labelSaveForLater: "Save for Later",
    labelReplayThisBroadcast: "Replay this Broadcast",
    labelPageNotFound: "We couldn't find the page you were looking for",
    labelResendConfirmation: "Resend Confirmation",
    labelGoToLogin: "Go To Login",
    labelAutoApprovePost: "Auto Approve Post",
    labelAccepted: "Accepted",
    labelInactive: "Inactive",
    labelUser: "User",
    labelManager: "Manager",
    labelSaveUser: "Save User",
    errMessageForUpdateAutoApprove: "User should be manager to auto approve post.",
    labelPlayPPTV: "Play PTTV",
    labelStopPlayPPTV: "Stop Playing PTTV",
    pptvPlayMessage: "PTTV played successfully.",
    pptvStopMessage: "PTTV stopped successfully.",
    labelCancel: "Cancel",
    labelNewBroadcast: "New Broadcast",
    labelFromPrevious: "From Previous",
    labelChooseDeliveryType: "Choose Delivery Type",
    labelBlockUser: "You have unsaved information, are you sure you want to leave this page?",
    labelSubmitted: "Submitted",
    labelApproved: "Approved",
    labelApprovePost: "Approve Post",
    labelShowCarColor: "Show/Download car images",
    labelToggleSpanish: "Toggle Spanish",
    labelNoPostAvailable: "No posts available",
    poorInternetConnection: "You have a poor internet connection and the application may be slow.",
    noDTVChannels: "This account does not have any DTV channels.",
    labelPreviewUploadedImg: "Preview uploaded image",
    reportBugUserName: "User Name",
    reportBudDelearship: "Dealership",
    reportBugAccount: "Account #",
    reportBugDescError: "Description of error",
    labelReportABug: "Send",
    reportBugDescriptionError: "Description of error can't be empty.",
    reportBugSuccessMsg: "Bug report submitted successfully",
    headingCameraIssue: `Known Bug in iOS 13.2 & 13.3`,
    textCameraIssue: `When the app goes into the background the camera will not load. The workaround is for the user to kill the app and reload it.`,
    labelDontShowAgain: `Don't show again`,
    labelMainMenu: `Main Menu`,
    labelHistory: `History`,
    labelSupport: `Support`,
    labelSignIn: `Sign In`,
    labelChangeColor: `Change Color`,
    labelChooseColor: `Choose Color`,
    labelPreview: `Preview`,
    labelSales: `sales`,
    confirmUnsafeContent: `This photo was flagged as unsafe, are you sure you want to approve?`,
    warningContentFlagged: `WARNING This post has been flagged as unsafe`,
    labelUserUpdateSuccess: `User has been updated`,
    labelPostDeleteSuccess: `Post has been deleted`
}
