import React, { useEffect, useState, useContext, Fragment } from 'react'
import './about.css';
import Loader from "../../components/Loader/loader"
import useRenderCount from "../../renderCountHook";
import ContactModule from "../../components/ContactModule/contactModule"
import addressInfoImage from '../../Assets/abn-badge.png';
import apiRequest from "../../api";
import ToastContext from "../../Context/toastContext"
import StringConstant from '../../stringConstant';
import { useTranslation } from "react-i18next";

const ABNInfo = (props) => {
   useRenderCount("ABOUT")
   const [compDetails, setCompDetails] = useState();
   const [dealerDetail, setDealerDetail] = useState();
   const [showLoader, setShowLoader] = useState(false);
   const { setMessage } = useContext(ToastContext);
   const { t } = useTranslation();
   ///--API call for company details///
   useEffect(() => {
      setShowLoader(true)
      const comapnyDetails = async () => {
         const response = await apiRequest('company', undefined, undefined, setMessage);
         setShowLoader(false)
         if (response) {
            setCompDetails(response)
         }
      };
      comapnyDetails()
      ///--API call for dealer details///
      const dealerDetail = async () => {
         const dealerResponse = await apiRequest(`accounts/current`, undefined, undefined, setMessage);
         if (dealerResponse) {
            setDealerDetail(dealerResponse)
         }
      }
      dealerDetail()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const company = compDetails || {};
   const dealer = dealerDetail || {};
   return (
      <Fragment>
         {showLoader && <Loader />}
         <div id="container" className="container about-body">
            <div className="about">
               <div className="banner">
                  <img src={addressInfoImage} alt="abn-logo" />
               </div>
               <h2 className="name">{company.name}</h2>
               <div className="title">{t(StringConstant.labelAppVersion)}</div>
               <div className="actions">
                  <div className="follow-phone desc">
                     <ul>
                        <li>{company.address_1}</li>
                        <li>{company.address_2}</li>
                     </ul>
                  </div>
               </div>
               <div className="row m-0">
                  <div className='col-6'>
                     <ContactModule aboutTitle={t(StringConstant.labelContactDetails)} alt="Contact Support" hrefemail={company.support_email} hreftelephone={company.support_phone} />
                  </div>
                  <div className='col-6'>
                     <ContactModule aboutTitle={t(StringConstant.labelRam)} alt="RAM" hrefemail={dealer.ram_email} hreftelephone={dealer.ram_phone_number} />
                  </div>
                  <div className='col-6'>
                     <ContactModule aboutTitle={t(StringConstant.labelRamSupervisor)} alt="RAM Supervisor" hrefemail={company.ram_supervisor_email} hreftelephone={company.ram_supervisor_phone} />
                  </div>
                  <div className='col-6'>
                     <ContactModule aboutTitle={t(StringConstant.labelBillingContact)} alt="Billing Contact" hrefemail={company.billing_contact_email} hreftelephone={company.billing_contact_phone} />
                  </div>
               </div>
               <div className="desc">
                  <ul>
                     <li class='acc'>{dealer.name}</li>
                     <li class='acc'><small>#{dealer.dealer_number}</small></li>
                  </ul>
                  <br/>
                  <p className="copyr">&copy; {t(StringConstant.labelCopyright)}</p>
               </div>
            </div>
         </div>
      </Fragment>
   )
}

export default ABNInfo
